import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duvidas',
  templateUrl: './duvidas.component.html',
  styleUrls: ['./duvidas.component.scss']
})
export class DuvidasComponent implements OnInit {
  itemDuvidas = [
    {"id":1,"question":"Quantas pessoas posso levar para o meu apartamento?","answer":"Nossos empreendimentos contam com uma infraestrutura de qualidade para te receber da melhor forma. Você pode conferir essa informação na secção de Vantagens dentro da página de cada produto."},
    {"id":2,"question":"Terei alimentação incluída?","answer":"Nossos empreendimentos não disponibilizam alimentação inclusa em suas diárias, mas os apartamentos contam com cozinha integrada, além disso, contamos com uma ótima área de alimentação para você e sua família."},
    {"id":3,"question":"Onde posso tirar dúvidas e obter informações sobre meu novo patrimônio de férias?","answer":"Nossa central de atendimento está disponível para lhe atender através de vários canais de comunicação, assim você pode escolher a opção que melhor se encaixa em sua necessidade. Estamos disponível para ligações através do número de telefone 3003-0720, no WhatsApp (62) 99861-1633, no -email atendimento@wambrasil.com, no Skype: wamnegocios e também via chat no nosso site www.wamcomercializacao.com"},
    {"id":4,"question":"Quando começo a pagar a primeira parcela do meu contrato?","answer":"A primeira parcela será no mês seguinte à compra, após a aquisição de seu patrimônio de férias. Essa informação também estará especificada em seu contrato. Os boletos para pagamento são enviados pelos correios, por e-mail e em caso de não recebimento você poderá retirá-lo diretamente no site da Natos (no caso do Solar das Águas e Olímpia Park) ou no site da WPA Gestão (demais empreendimentos)."},
    {"id":5,"question":"Como posso utilizar minhas diárias?","answer":"Para usufruir de suas diárias é necessário que esteja adimplente com as parcelas do contrato e do condomínio, além de precisar informar o hotel com 10 dias de antecedência. Cada empreendimento possui um valor mínimo de quitação das parcelas para o primeiro uso, essa informação encontra-se no seu contrato."},
    {"id":6,"question":"Eu posso utilizar meu apartamento/cota quando quiser?","answer":"É necessário que você sempre consulte o cronograma de uso para saber quais são as suas datas de utilização, mas caso queira ir em outra data, basta entrar em contato com o Club Cia e utilizar seu benefício de intercâmbio. Mediante a disponibilidade do hotel você poderá solicitar a troca de seu período."},
    {"id":7,"question":"O que é o cronograma de uso? ","answer":"O cronograma de uso foi criado para possibilitar a utilização de forma igualitária por todos os multiproprietários de cada apartamento. Você receberá uma tabela que irá indicar quais são os dias que você e sua família poderão fazer uso, onde essas datas são pré-definidas e se alternam no decorrer de cada ano, proporcionando que todos tenham oportunidade de viajar em diferentes períodos."},
    {"id":8,"question":"Descontos Club Cia quais são e como funcionam?","answer":"O Club Cia oferece uma equipe de consultores qualificados e prontos para esclarecerem dúvidas e darem dicas de viagens de forma personalizada. Além de pacotes aéreos e hospedagens, o Club Cia também comercializa ingressos para parques aquáticos, aluguéis de carros, seguro viagem e vários outros produtos. Um clube de viagens com benefícios incomparáveis e atendimento exclusivo para clientes associados. O Club Cia é especialista em trade hoteleiro e oferece um guia de destinos com o melhor custo-benefício aos seus associados."},
    {"id":9,"question":"Caso eu não queira utilizar minhas diárias dentro do cronograma, quais são minhas opções?","answer":"Se você não puder ou não quiser utilizar suas diárias pré-definidas no cronograma de uso, terá diversas opções como o intercâmbio, a monetização, o aluguel de forma particular ou disponibilizar a utilização para terceiros. Você terá autonomia para decidir qual a melhor opção. Fale com seu consultor de viagens no Club Cia para maiores informações."},
    {"id":10,"question":"Quais os destinos já disponíveis para intercâmbio?","answer":"Atualmente temos os destinos de Caldas Novas-GO, Gramado-RS, Suzano-SP, Búzios-RJ, Campos do Jordão-SP, Domingos Martins-ES e Olímpia-SP."},
    {"id":11,"question":"O IPTU será cobrado junto com as parcelas do condomínio?","answer":"Após a entrega de seu empreendimento, você poderá entrar em contato com a administradora e verificar qual a forma de cobrança desse valor."},
    {"id":12,"question":"Como faço para ter a escritura do meu patrimônio de férias? Podemos fazer isso de nossa residência?","answer":"A escritura é feita após a entrega do seu empreendimento, onde a Administradora Natos ou WPA Gestão irá fornecer os documentos necessários para que você possa fazer a escrituração de seu imóvel que é feita sempre na cidade que ele se encontra. Todas as taxas são pagas diretamente ao cartório."},   
    {"id":14,"question":"Onde consigo retirar os boletos do meu contrato?","answer":"Com sua administradora WPA Gestão, através do portal do cliente e também através do aplicativo Minha WPA."},
    {"id":15,"question":"Onde posso acompanhar o andamento da obra do meu empreendimento?","answer":"As administradoras WPA Gestão e Natos disponibilizam fotos e detalhes sobre a obra, sempre atualizadas para que você possa acompanhar seu sonho de férias de perto, através dos portais do cliente https://portal.wpagestao.com.br/portal/ e http://www.gruponatos.com.br/home"},
    {"id":16,"question":"Como funciona o intercâmbio?","answer":"É o programa do Club Cia que permite você realize a troca do seu período de férias estabelecidos em seu cronograma de uso, podendo ser feito no mesmo empreendimento que é proprietário ou em outro empreendimento também comercializado pela WAM, desde que esteja entregue."},
    {"id":17,"question":"Como funciona a monetização?","answer":"Você terá a opção de utilizar a sua semana como moeda de troca. A monetização é o novo avanço do programa de intercâmbio de destinos Club Cia. Com ele, após a liberação e avaliação do clube, você pode transformar sua semana de uso ou período de utilização em carta de crédito gerando poder de compra para os diversos serviços oferecidos pelo Club Cia."},
    {"id":18,"question":"Quando for utilizar minhas diárias, preciso levar roupas de cama, toalhas, entre outros?","answer":"Você não precisa se preocupar com roupas de cama e banho, ou utensílios domésticos. Nossos apartamentos são todos equipados para que você e sua família possam ter uma estrutura de hotel, sempre com conforto e qualidade e sem nenhuma preocupação."}
  ];;
  constructor() { }

  ngOnInit(): void {
  }

}
