import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { HttpRequestsService } from "../services/http-requests.service";
import { ViacepService, ViaCEP } from "../services/viacep.service";
import { GalaxPayService } from "../services/galax-pay.service";
import * as moment from "moment";
import produtos from 'src/assets/produtos.json';

@Component({
    selector: "app-form-venda",
    templateUrl: "./form-venda.component.html",
    styleUrls: ["./form-venda.component.scss"],
})
export class FormVendaComponent implements OnInit {
    empreendimento: any;
    listUnidades: any[] = [];
    listCotas: any[] = [];
    contato: ContactData;
    cotaCarregada = false;
    phoneMask = '(00) 0000-00009';
    phoneMask1 = '(00) 0000-00009';
    carregandoRG = false;
    carregandoCPF = false;
    carregandoRGc = false;
    carregandoCPFc = false;
    fileError = "";
    validation = "";
    carregando = false;
    sendValidation = "";
    sendOK = false;
    linkTermo = "#";
    linkExpecificacoes = "#";
    carregandoCEP = false;
    cepinvalido = false;
    cep: ViaCEP = new ViaCEP();
    showFone2 = false;
    leadSaved = false;
    activeAccordion = "ngb-panel-0";
    cardBrand: any;
    listYears: any[] = [];
    brands: any[] = [];
    parcelas: any[] = [];
    saleStep = "";
    salePerc = "";
    sign_1 = 'up';  // or minus if you want that first
    toggleSign(id:number) {
        switch(id) { 
            case 1: { 
                if(this.sign_1 == 'up') {
                    this.sign_1 = 'down';
                } else {
                    this.sign_1 = 'up';
                }
                break;
            }
        }
    }

    produto : any;
    
    public itemBuilds = produtos;

    constructor(
        private route: ActivatedRoute,
        private http: HttpRequestsService,
        private routelink: Router,
        private viacep: ViacepService,
        private galaxpay: GalaxPayService
    ) { }

    public saveLead(): void {
        if (
            this.contato.comprador.nome !== "" &&
            this.contato.contato.email !== "" &&
            this.contato.contato.telefone1 !== ""
        ) {
            const lead = {
                nome: this.contato.comprador.nome,
                telefone: this.contato.contato.telefone1,
                email: this.contato.contato.email,
                origem: "formulario-vendas-"+this.produto['name-bi'],
            };
            this.http.loadAPIAppData("saveLead", lead).subscribe((res: any) => {
                if (!res.success) {
                    console.error(res);
                }
            });
            this.leadSaved = true;
            setTimeout(() => {
                this.activeAccordion = "ngb-panel-1";
            }, 100);
        } else {

        }
    }

    public phoneChange(event) {
        const phoneNumber = String(event.target.value).replace(/\D/g, "");
        if (phoneNumber.length <= 0) {
            this.phoneMask = "00";
        } else if (phoneNumber.length < 11) {
            this.phoneMask = "(00) 0000-00009";
        } else {
            this.phoneMask = "(00) 0 0000-0000";
        }
    }

    public phoneChange1(event) {
        const phoneNumber = String(event.target.value).replace(/\D/g, "");
        if (phoneNumber.length <= 0) {
            this.phoneMask1 = "00";
        } else if (phoneNumber.length < 11) {
            this.phoneMask1 = "(00) 0000-00009";
        } else {
            this.phoneMask1 = "(00) 0 0000-0000";
        }
    }

    public buscaCEPData(cep: string): void {
        this.cepinvalido = false;
        cep = cep.replace(/\D/g, "");
        if (cep.length === 8) {
            this.carregandoCEP = true;
            this.viacep.getAddressByCEP(cep).subscribe((res: ViaCEP) => {
                this.cep = res;
                this.carregandoCEP = false;
                this.cepinvalido = !res.status;
                if (res.status) {
                    this.contato.endereco.logradouro = res.logradouro
                        ? res.logradouro
                        : "";
                    // this.contato.endereco.complemento = (res.complemento) ? res.complemento : '';
                    this.contato.endereco.bairro = res.bairro ? res.bairro : "";
                    this.contato.endereco.cidade = res.localidade ? res.localidade : "";
                    this.contato.endereco.uf = res.uf ? res.uf : "";
                } else {
                    this.contato.endereco.logradouro = "";
                    // this.contato.endereco.complemento = '';
                    this.contato.endereco.bairro = "";
                    this.contato.endereco.cidade = "";
                    this.contato.endereco.uf = "";
                }
            });
        }
    }

    public changeFile(event: any, type: string): void {
        this.fileError = "";
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                const text: any = String(reader.result).replace(
                    "data:image/jpeg;base64,",
                    ""
                );
                this.contato.documentos.rg =
                    type === "rg" ? text : this.contato.documentos.rg;
                this.contato.documentos.cpf =
                    type === "cpf" ? text : this.contato.documentos.cpf;
                this.contato.documentos.rgConjuge =
                    type === "rgconjuge" ? text : this.contato.documentos.rgConjuge;
                this.contato.documentos.cpfConjuge =
                    type === "cpfconjuge" ? text : this.contato.documentos.cpfConjuge;
                this.carregandoRG = false;
                this.carregandoCPF = false;
                this.carregandoRGc = false;
                this.carregandoCPFc = false;
            };
            if (event.target.files[0].size <= 2 * 1024 * 1024) {
                this.carregandoRG = type === "rg";
                this.carregandoCPF = type === "cpf";
                this.carregandoRGc = type === "rgconjuge";
                this.carregandoCPFc = type === "cpfconjuge";
                reader.readAsDataURL(event.target.files[0]);
            } else {
                this.fileError = "Tamanho máximo do arquivo deve ser de 2 mb";
            }
        } else {
        }
    }

    private verificaObrigatorios(formValue: any): boolean {
        this.validation = "";
        let retorno = true;
        const obrigatorios = [
            {
                campo: "aceite_termo",
                nome: "Aceite dos termos e condições de compra",
            },
            { campo: "contrato_centroCusto", nome: "Empreendimento" },
            { campo: "contrato_unidade", nome: "Unidade" },
            { campo: "contrato_cota", nome: "Cota" },
            { campo: "comprador_nome", nome: "Nome do comprador" },
            {
                campo: "comprador_dataNascimento",
                nome: "Data de nascimento do comprador",
            },
            { campo: "comprador_nacionalidade", nome: "Nacionalidade do comprador" },
            { campo: "comprador_estadoCivil", nome: "Estado civíl" },
            { campo: "comprador_profissao", nome: "Profissão do comprador" },
            { campo: "comprador_rg", nome: "RG do comprador" },
            {
                campo: "comprador_orgaoExpedidor",
                nome: "Orgão expedidor do comprador",
            },
            { campo: "comprador_cpf", nome: "CPF do comprador" },
            { campo: "contato_telefone1", nome: "Telefone" },
            { campo: "contato_email", nome: "E-mail" },
            { campo: "endereco_cep", nome: "CEP" },
            { campo: "endereco_logradouro", nome: "Logradouro" },
            { campo: "endereco_numero", nome: "Número" },
            { campo: "endereco_bairro", nome: "Bairro" },
            { campo: "endereco_cidade", nome: "Cidade" },
            { campo: "endereco_uf", nome: "UF" },
            // { campo: 'testemunha_nome', nome: 'Nome da testemunha' },
            // { campo: 'testemunha_email', nome: 'E-mail da testemunha' }
            // { campo: 'documentos_rg', nome: 'Documento (RG)' },
            // { campo: 'documentos_cpf', nome: 'Documento (CPF)' },
        ];
        if (
            formValue.comprador_estadoCivil === "Casado" ||
            formValue.comprador_estadoCivil === "União Estável"
        ) {
            obrigatorios.push({ campo: "conjuge_nome", nome: "Nome do conjuge" });
            obrigatorios.push({
                campo: "conjuge_dataNascimento",
                nome: "Data de nascimento do conjuge",
            });
            obrigatorios.push({
                campo: "conjuge_nacionalidade",
                nome: "Nacionalidade do conjuge",
            });
            obrigatorios.push({
                campo: "conjuge_regimeCasamento",
                nome: "Regime de casamento",
            });
            obrigatorios.push({
                campo: "conjuge_profissao",
                nome: "Profissão do conjuge",
            });
            obrigatorios.push({ campo: "conjuge_rg", nome: "RG do conjuge" });
            obrigatorios.push({
                campo: "conjuge_orgaoExpedidor",
                nome: "Órgão expedidor do conjuge",
            });
            obrigatorios.push({ campo: "conjuge_cpf", nome: "CPF do conjuge" });
            obrigatorios.push({ campo: "conjuge_email", nome: "E-mail do conjuge" });
            // obrigatorios.push({ campo: 'documentos_rgConjuge', nome: 'Documento (RG) do conjuge' });
            // obrigatorios.push({ campo: 'documentos_cpfConjuge', nome: 'Documento (CPF) do conjuge' });
        }
        /*
        if (formValue.testemunha_nome !== '') {
            obrigatorios.push({ campo: 'testemunha_email', nome: 'E-mail da testemunha' });
        }
        if (formValue.testemunha_email !== '') {
            obrigatorios.push({ campo: 'testemunha_nome', nome: 'Nome da testemunha' });
        }*/
        if (this.contato.pagamento.valorEntrada > 0) {
            obrigatorios.push({
                campo: "pagamento_number",
                nome: "Número do cartão de crédito",
            });
            obrigatorios.push({
                campo: "pagamento_holder",
                nome: "Nome impresso no cartão de crédito",
            });
            obrigatorios.push({
                campo: "pagamento_expiryMonth",
                nome: "Mês de vencimento do cartão de crédito",
            });
            obrigatorios.push({
                campo: "pagamento_expiryYear",
                nome: "Ano de vencimento do cartão de crédito",
            });
            obrigatorios.push({
                campo: "pagamento_cvv",
                nome: "Código de segurança impresso no cartão de crédito",
            });
            obrigatorios.push({
                campo: "pagamento_brand",
                nome: "Bandeira do cartão de crédito",
            });
        }
        for (const element of obrigatorios) {
            if (formValue[element.campo] === "") {
                retorno = false;
                this.validation = element.nome;
                return retorno;
            }
        }
        if (
            formValue.comprador_estadoCivil === "Casado" &&
            formValue.contato_email === formValue.conjuge_email
        ) {
            retorno = false;
            this.validation =
                "E-mail do conjuge (deve ser diferente do e-mail do comprador)";
        }
        return retorno;
    }

    private increasePerc(): void {
        setTimeout(() => {
            if (this.carregando) {
                if (!isNaN(Number(this.salePerc))) {
                    if (Number(this.salePerc) < 100) {
                        this.salePerc = String(Number(this.salePerc) + 1);
                        this.increasePerc();
                    }
                }
            }
        }, 2500);
    }

    private cancelaGalaxyPay(billInternalId: number): void {
        const payload = {
            action: "cancelPaymentBill",
            payload: {
                paymentBillInternalId: billInternalId,
            },
        };
        this.galaxpay.loadAPIAppData(payload).subscribe((res: any) => {
            console.warn(res);
        });
    }

    private consultaGalaxPay(
        billInternalId: number,
        integrationId: string
    ): void {
        console.log('consultaGalaxPay');
        const payload = {
            action: "getTransactionsByPaymentBill",
            payload: {
                internalId: billInternalId,
            },
        };
        this.galaxpay.loadAPIAppData(payload).subscribe((res: any) => {
            if (res.success) {
                if (res.response.type) {
                    switch (res.response.transactions[0].status) {
                        case "captured":
                        case "authorized":
                            this.saleStep = "Finalizando...";
                            this.salePerc = "90";
                            // envia dados para dynamics
                            this.http
                                .loadAPIAppData("saveCustomerDataAuthCode", {
                                    integrationId: integrationId,
                                    authcode: res.response.transactions[0].authorizationCode,
                                    pagamento: this.contato.pagamento,
                                    cardData: {
                                        brand: this.contato.pagamento.brand,
                                        number: this.contato.pagamento.number,
                                        holder: this.contato.pagamento.holder,
                                        expiryMonth: this.contato.pagamento.expiryMonth,
                                        expiryYear: this.contato.pagamento.expiryYear,
                                        cvv: this.contato.pagamento.cvv,
                                    },
                                })
                                .subscribe((save: any) => {
                                    this.salePerc = "100";
                                    if (save.success) {
                                        setTimeout(() => {
                                            this.sendOK = true;
                                            this.routelink.navigate(["/parabens"]);
                                        }, 700);
                                    } else {
                                        this.saleStep = "";
                                        this.salePerc = "0";
                                        this.carregando = false;
                                        this.sendValidation = save.msg;
                                    }
                                });
                            break;
                        case "denied":
                            this.saleStep = "";
                            this.salePerc = "0";
                            this.carregando = false;
                            this.sendValidation = "Operação negada pela operadora do cartão.";
                            this.cancelaGalaxyPay(billInternalId);
                            break;
                        default:
                            // aguardando
                            setTimeout(() => {
                                this.saleStep =
                                    "Nova tentativa de consulta do status da venda junto a operadora do cartão...";
                                console.log("Nova tentativa...");
                                this.consultaGalaxPay(billInternalId, integrationId);
                            }, 5000);
                            break;
                    }
                } else {
                    this.saleStep = "";
                    this.salePerc = "0";
                    this.carregando = false;
                    this.sendValidation = res.response.message;
                }
            } else {
                this.saleStep = "";
                this.salePerc = "0";
                this.carregando = false;
                this.sendValidation = res.msg;
            }
        });
    }

    public submitContactData(f: NgForm): void {
        this.sendValidation = "";
        this.sendOK = false;
        this.validation = "";
        if (this.verificaObrigatorios(f.value)) {
            this.carregando = true;
            this.increasePerc();
            // Step 1 - cadastra cliente no noso banco
            this.saleStep = "Validando dados...";
            this.salePerc = "0";
            this.http.loadAPIAppData("saveCustomerData", this.contato).subscribe(
                (res: any) => {
                    if (res.success) {
                        if (this.contato.pagamento.valorEntrada > 0) {
                            // verifica se usuário já está cadastrado no galaxpay
                            this.saleStep =
                                "Verificando dados do cliente junto a operadora do cartão...";
                            this.salePerc = "20";
                            let payload: any = {
                                action: "getCustomerInfo",
                                payload: {
                                    customerDocument: this.contato.comprador.cpf,
                                },
                            };
                            this.galaxpay.loadAPIAppData(payload).subscribe((gres: any) => {
                                if (gres.success) {
                                    payload = {
                                        action: "",
                                        payload: {
                                            document: this.contato.comprador.cpf,
                                            name: this.contato.comprador.nome,
                                            email: this.contato.contato.email,
                                            phone: this.contato.contato.telefone1,
                                            Address: {
                                                zipCode: this.contato.endereco.cep,
                                                street: this.contato.endereco.logradouro,
                                                number: this.contato.endereco.numero,
                                                neighborhood: this.contato.endereco.bairro,
                                                city: this.contato.endereco.cidade,
                                                state: this.contato.endereco.uf,
                                                complement: this.contato.endereco.complemento,
                                            },
                                        },
                                    };
                                    // valida dados no galaxpay
                                    this.saleStep =
                                        "Atualizando dados do cliente junto a operadora do cartão...";
                                    this.salePerc = "40";
                                    if (gres.response.type) {
                                        // cliente já cadastrado, faz o update dos dados
                                        payload.payload.customerInternalId =
                                            gres.response.customer.internalId;
                                        payload.action = "updateCustomer";
                                    } else {
                                        // cliente nao cadastrado, cria ele
                                        payload.action = "createCustomer";
                                    }
                                    this.galaxpay
                                        .loadAPIAppData(payload)
                                        .subscribe((gres2: any) => {
                                            if (gres2.success) {
                                                if (gres2.response.type) {
                                                    // cria venda no galaxpay
                                                    this.saleStep =
                                                        "Atualizando dados da venda junto a operadora do cartão...";
                                                    this.salePerc = "60";
                                                    payload = {
                                                        action: "createPaymentBill",
                                                        payload: {
                                                            integrationId: res.returnData.integrationId,
                                                            customerInternalId: gres2.response
                                                                .customerInternalId
                                                                ? gres2.response.customerInternalId
                                                                : gres.response.customer.internalId,
                                                            typeBill: "contract",
                                                            periodicity: "monthly",
                                                            quantity: 1, // this.contato.pagamento.numParcelas,
                                                            payday: moment().format("YYYY-MM-DD"),
                                                            value: this.contato.pagamento.valorEntrada.toFixed(
                                                                2
                                                            ),
                                                            paymentType: "newCard",
                                                            Card: {
                                                                number: this.contato.pagamento.number,
                                                                holder: this.contato.pagamento.holder,
                                                                expiryMonth: this.contato.pagamento.expiryMonth,
                                                                expiryYear: this.contato.pagamento.expiryYear,
                                                                cvv: this.contato.pagamento.cvv,
                                                                brand: this.contato.pagamento.brand,
                                                            },
                                                        },
                                                    };
                                                    this.galaxpay
                                                        .loadAPIAppData(payload)
                                                        .subscribe((gres3: any) => {
                                                            if (gres3.success) {
                                                                if (gres3.response.type) {
                                                                    // consulta venda no galaxpay
                                                                    this.saleStep =
                                                                        "Consultando status da venda junto a operadora do cartão...";
                                                                    this.salePerc = "80";
                                                                    this.consultaGalaxPay(
                                                                        gres3.response.paymentBillInternalId,
                                                                        res.returnData.integrationId
                                                                    );
                                                                } else {
                                                                    this.saleStep = "";
                                                                    this.salePerc = "0";
                                                                    this.carregando = false;
                                                                    this.sendValidation = gres3.response.message;
                                                                }
                                                            } else {
                                                                this.saleStep = "";
                                                                this.salePerc = "0";
                                                                this.carregando = false;
                                                                this.sendValidation = gres3.msg;
                                                            }
                                                        });
                                                } else {
                                                    this.saleStep = "";
                                                    this.salePerc = "0";
                                                    this.carregando = false;
                                                    this.sendValidation = gres2.response.message;
                                                }
                                            } else {
                                                this.saleStep = "";
                                                this.salePerc = "0";
                                                this.carregando = false;
                                                this.sendValidation = gres2.msg;
                                            }
                                        });
                                } else {
                                    this.saleStep = "";
                                    this.salePerc = "0";
                                    this.carregando = false;
                                    this.sendValidation = gres.msg;
                                }
                            });
                        } else {
                            this.sendOK = true;
                            this.routelink.navigate(["/parabens"]);
                        }
                    } else {
                        this.saleStep = "";
                        this.salePerc = "0";
                        this.carregando = false;
                        this.sendValidation = res.msg;
                    }
                },
                (err) => {
                    console.error(err);
                    this.carregando = false;
                    this.sendValidation =
                        "Houve um erro de comunicação com nosso servidor, por favor aguarde alguns instantes e tente novamente.";
                }
            );
        }
    }

    public loadEmpreendimento(empreendimentoId: string): void {
        const apiPayload = {
            data: {
                table: "view_produtosDisponiveis",
                fields: [
                    "empreendimento",
                    "unidade",
                    "bloco",
                    "tipo",
                    "pavimento",
                    "vista",
                    "posicaoSol",
                ],
                filters: [["centroCusto", "=", empreendimentoId]],
                order: [{ column: "unidade", direction: "ASC" }]
            },
        };
        this.http.loadAPIAppData("listData", apiPayload).subscribe((res: any) => {
            if (res.success && res.numRows > 0) {
                this.contato.contrato.centroCusto = empreendimentoId;
                this.empreendimento = res.result[0].empreendimento;
                this.listUnidades = res.result.numRows;
                this.contato.contrato.unidade = res.result[0].unidade;
                this.loadCotas(empreendimentoId, res.result[0].unidade);
            } else {
                this.routelink.navigate(["/home"]);
            }
        });
    }

    public changeCreditCard(event: any) {
        this.brands.forEach((element) => {
            if (element.name === event) {
                this.parcelas = [];
                const max =
                    element.maxNumberOfInstallments > 12
                        ? 12
                        : element.maxNumberOfInstallments;
                for (let i = 1; i <= max; i++) {
                    this.parcelas.push(i);
                }
                this.contato.pagamento.brand = event;
            }
        });
    }

    public detectCardType(cardnumber: string) {
        const strTest = cardnumber.replace(/\D/g, "");
        this.cardBrand = strTest.length > 0 ? this.cardBrand : undefined;
        const brands = [
            {
                brand: "elo",
                icon: '<i class="far fa-credit-card"></i>',
                regex: /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/g,
            },
            {
                brand: "hipercard",
                icon: '<i class="far fa-credit-card"></i>',
                regex: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/g,
            },
            {
                brand: "diners",
                icon: '<i class="fab fa-cc-diners-club"></i>',
                regex: /^3(0[0-5]|[68]\d)\d{11}$/g,
            },
            {
                brand: "amex",
                icon: '<i class="fab fa-cc-amex"></i>',
                regex: /^3[47]\d{13}$/g,
            },
            {
                brand: "mastercard",
                icon: '<i class="fab fa-cc-mastercard"></i>',
                regex: /^(5[1-5]\d{4}|677189)\d{10}$/g,
            },
            {
                brand: "visa",
                icon: '<i class="fab fa-cc-visa"></i>',
                regex: /^4\d{12}(\d{3})?$/g,
            },
            {
                brand: "discover",
                icon: '<i class="fab fa-cc-discover"></i>',
                regex: /^6(?:011|5[0-9]{2})[0-9]{12}$/g,
            },
            {
                brand: "jcb",
                icon: '<i class="fab fa-cc-jcb"></i>',
                regex: /^(?:2131|1800|35\d{3})\d{11}$/g,
            },
            {
                brand: "aura",
                icon: '<i class="far fa-credit-card"></i>',
                regex: /^(5078\d{2})(\d{2})(\d{11})$/g,
            },
            {
                brand: "maestro",
                icon: '<i class="far fa-credit-card"></i>',
                regex: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/g,
            },
        ];
        brands.forEach((element) => {
            if (element.regex.test(strTest)) {
                this.cardBrand = { brand: element.brand, icon: element.icon };
            }
        });
        if (this.cardBrand) {
            this.brands.forEach((element) => {
                if (element.name === this.cardBrand.brand) {
                    this.contato.pagamento.brand = element.name;
                    this.changeCreditCard(element.name);
                }
            });
        }
    }

    public loadCotas(empreendimentoId: string, unidade: string): void {
        const apiPayload = {
            data: {
                table: "view_cotasDisponiveis",
                fields: ["cota", "valorTotal", "valorEntrada", "numParcelas"],
                filters: [
                    ["centroCusto", "=", empreendimentoId],
                    ["unidade", "=", unidade],
                ],
                order: [{ column: "cota", direction: "ASC" }],
            },
        };
        this.http.loadAPIAppData("listData", apiPayload).subscribe((res: any) => {
            if (res.success) {
                this.cotaCarregada = true;
                this.listCotas = res.result;
                this.contato.contrato.cota = res.result[0].cota;
                this.contato.pagamento.valorTotalStr = new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }).format(res.result[0].valorTotal);
                this.contato.pagamento.valorTotal = res.result[0].valorTotal;
                this.contato.pagamento.valorEntradaStr = new Intl.NumberFormat(
                    "pt-BR",
                    { style: "currency", currency: "BRL" }
                ).format(res.result[0].valorEntrada);
                this.contato.pagamento.valorEntrada = res.result[0].valorEntrada;
                this.contato.pagamento.numParcelas = String(res.result[0].numParcelas);
                const parc =
                    Math.round(
                        (res.result[0].valorTotal / res.result[0].numParcelas) * 100
                    ) / 100;
                this.contato.pagamento.valorParcela = parc;
                this.contato.pagamento.valorParcelaStr = new Intl.NumberFormat(
                    "pt-BR",
                    { style: "currency", currency: "BRL" }
                ).format(parc);
            }
        });
    }

    ngOnInit() {
        this.empreendimento = {
            empreendimento: '',
            bloco: ',',
            pavimento: '',
            posicaoSol: '',
            tipo: '',
            unidade: '',
            vista: ''
        };
        // tslint:disable-next-line: no-use-before-declare
        this.contato = new ContactData();
        this.route.params.subscribe((res: any) => {
            this.loadEmpreendimento(res.id);
            this.produto = this.itemBuilds.find(e => e.id === parseInt(res.id)); 
            this.linkTermo = `../../assets/pdf/termo_empreendimento_${res.saleId}.pdf`;
            this.linkTermo = this.linkTermo.replace(':', '');
            this.linkExpecificacoes = `../../assets/pdf/detalhes_${res.saleId}.pdf`;
        });
        const endYear = Number(moment().format('YYYY')) + 15;
        for (let index = Number(moment().format('YYYY')); index < endYear; index++) {
            this.listYears.push(index);
        }
        // load brands
        const payload = {
            action: 'getOperatorList',
            payload: {
                operation: 'getOperadorList'
            }
        };
        this.galaxpay.loadAPIAppData(payload).subscribe((res: any) => {
            if (res.success && res.response.type) {
                const payload2 = {
                    action: 'getCardBrandsByOperator',
                    payload: {
                        operatorName: res.response.operators[0].name
                    }
                };
                this.galaxpay.loadAPIAppData(payload2).subscribe((res2: any) => {
                    if (res2.success && res2.response.type) {
                        this.brands = res2.response.brands;
                    } else {
                        this.sendValidation = 'Não foi possível obter a lista de bandeiras de cartão de crédito disponíveis.';
                    }
                });
            } else {
                this.sendValidation = 'Não foi possível obter a lista de operadoras de cartão de crédito disponíveis.';
            }
        });
    }
}

export class ContactData {
    // tslint:disable-next-line: no-use-before-declare
    contrato: Contrato = new Contrato();
    // tslint:disable-next-line: no-use-before-declare
    comprador: Comprador = new Comprador();
    // tslint:disable-next-line: no-use-before-declare
    contato: Contato = new Contato();
    // tslint:disable-next-line: no-use-before-declare
    conjuge: Conjuge = new Conjuge();
    // tslint:disable-next-line: no-use-before-declare
    endereco: Endereco = new Endereco();
    // tslint:disable-next-line: no-use-before-declare
    testemunha: Testemunha = new Testemunha();
    // tslint:disable-next-line: no-use-before-declare
    documentos: Documentos = new Documentos();
    // tslint:disable-next-line: no-use-before-declare
    pagamento: Pagamento = new Pagamento();
}

export class Contrato {
    centroCusto = "";
    unidade = "";
    cota = "";
}

export class Comprador {
    nome = "";
    dataNascimento = "";
    nacionalidade = "";
    estadoCivil = "";
    profissao = "";
    rg = "";
    orgaoExpedidor = "";
    cpf = "";
}

export class Contato {
    telefone1 = "";
    telefone2 = "";
    email = "";
}

export class Conjuge {
    nome = "";
    dataNascimento = "";
    nacionalidade = "";
    regimeCasamento = "";
    profissao = "";
    rg = "";
    orgaoExpedidor = "";
    cpf = "";
    email = "";
    telefone1 = "";
    telefone2 = "";
}

export class Endereco {
    cep = "";
    logradouro = "";
    numero = "";
    complemento = "";
    bairro = "";
    cidade = "";
    uf = "";
}

export class Testemunha {
    nome = "";
    email = "";
}

export class Documentos {
    rg = "";
    cpf = "";
    rgConjuge = "";
    cpfConjuge = "";
}

export class Pagamento {
    number = "";
    holder = "";
    cpf = "";
    expiryMonth = "";
    expiryYear = "";
    cvv = "";
    brand = "";
    valorTotal = 0;
    valorTotalStr = "";
    valorParcela = 0;
    valorParcelaStr = "";
    valorEntrada = 236.90;
    valorEntradaStr = "R$ 236,90";
    numParcelas = "";
    tipoRecebimento = 0;
}
