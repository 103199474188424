<div class="container mt-5 mb-5 container-first">
    <div class="row">
        <div class="col-md-12">
            <p class="duvidas-title">Dúvidas Frequentes</p>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <p class="duvidas-text-search">Busque por palavra-chave</p>
            <div class="md-form">
                <i class="fas fa-search prefix"></i>
                <input type="text" id="search" class="form-control" placeholder="Ex: Cronograma de uso">
            </div>
        </div>
    </div>
    <div class="row mt-3" *ngFor="let itemDuvida of itemDuvidas">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <p class="duvidas-text-question">
                        {{itemDuvida.question}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="duvidas-text-answer">
                        {{itemDuvida.answer}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
