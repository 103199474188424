
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule} from 'ngx-mask'

import { AppComponent } from './app.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { DuvidasComponent } from './duvidas/duvidas.component';
import { AppRoutingModule } from './app-routing.module';
import { FormVendaComponent } from './form-venda/form-venda.component';
import { ProdutoDetailComponent } from './produto-detail/produto-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuProdutoComponent } from './menu-produto/menu-produto.component';
import { ParabensComponent } from './parabens/parabens.component';
import { MenuProdutoDetailComponent } from './menu-produto-detail/menu-produto-detail.component';
import { FooterProdutoDetailComponent } from './footer-produto-detail/footer-produto-detail.component';
import { FooterCleanComponent } from './footer-clean/footer-clean.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';


import { StickyHeaderComponent } from './sticky-header';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    MenuComponent,
    DuvidasComponent,
    FormVendaComponent,
    ProdutoDetailComponent,
    MenuProdutoComponent,
    ParabensComponent,
    MenuProdutoDetailComponent,
    FooterProdutoDetailComponent,
    FooterCleanComponent,
    StickyHeaderComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    ScrollToModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
