<!-- NAVBAR -->
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light white-custom fixed-top" [containerInside]="true">
    <!-- Navbar brand -->
    <mdb-navbar-brand>
      <a class="navbar-brand" href="#">
        <img src="assets/logo.png" height="72px" alt="" />
      </a>
    </mdb-navbar-brand>
    <!-- Collapsible content -->
    <links>
      <!-- Links -->
      <ul class="navbar-nav mr-auto d-flex justify-content-center">
        <li  class="nav-item" *ngFor="let itemMenu of itemMenus">
          <a class="nav-link" mdbWavesEffect [routerLink]="['home']" fragment="{{itemMenu.url}}">{{itemMenu.name}}</a>
        </li>
        <li  class="nav-item">
          <a class="nav-link" mdbWavesEffect routerLink="/duvidas">Dúvidas</a>
        </li>
          <div class="box-whatsapp">
            <a class="nav-link" mdbWavesEffect href="https://api.whatsapp.com/send?phone=5562998611633&text=Vendas%20Online" target="_blank">
              <img src="assets/whatsapp.png" height="27px" alt="" /> Fale conosco pelo Whatsapp
            </a>
          </div>
      </ul>
    </links>
  <!-- Collapsible content -->
</mdb-navbar>
<!-- /.NAVBAR -->