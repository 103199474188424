import { Component, OnInit,  HostListener, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import produtos from 'src/assets/produtos.json';

@Component({
  selector: 'app-produto-detail',
  templateUrl: './produto-detail.component.html',
  styleUrls: ['./produto-detail.component.scss'],
  animations: [
    trigger('scrollAnimation', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide',   style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ])
  ]
})
export class ProdutoDetailComponent implements OnInit {

  private fragment: string;
  state = 'hide';
  varandas = false;
  buzios = false;
  solar = false;
  terranova = false;
  pousada = false;
  olimpia =  false;

  sign_1 = 'up';  // or minus if you want that first
  sign_2 = 'up';  // or minus if you want that first
  sign_3 = 'up';  // or minus if you want that first
  sign_4 = 'up';  // or minus if you want that first
  toggleSign(id:number) {
    switch(id) { 
      case 1: { 
        if(this.sign_1 == 'up') {
          this.sign_1 = 'down';
        } else {
          this.sign_1 = 'up';
        }
        break;
      }
      case 2: { 
        if(this.sign_2 == 'up') {
          this.sign_2 = 'down';
        } else {
          this.sign_2 = 'up';
        }
        break;
      }
      case 3: { 
        if(this.sign_3 == 'up') {
          this.sign_3 = 'down';
        } else {
          this.sign_3 = 'up';
        }
        break;
      }
      case 4: { 
        if(this.sign_4 == 'up') {
          this.sign_4 = 'down';
        } else {
          this.sign_4 = 'up';
        }
        break;
      }
    }
  }
  produto : any;

  itemCarousels = [
    {"id":0,"name":"Olimpia","url":"assets/4.jpg","text":"O Olímpia Park Resort chegou!","link":"/produto/6451"},
    {"id":1,"name":"image1","url":"assets/0.jpg","text":"Seu sonho da casa de férias agora é realidade!","link":"home"},
    {"id":2,"name":"image2","url":"assets/1.jpg","text":"Seu sonho da casa de férias agora é realidade!","link":"home"},
    {"id":3,"name":"image3","url":"assets/2.jpg","text":"Seu sonho da casa de férias agora é realidade!","link":"home"},
    {"id":4,"name":"image4","url":"assets/3.jpg","text":"Seu sonho da casa de férias agora é realidade!","link":"home"}
  ];; 

  public itemBuilds = produtos;

  public itemsList: Object[] = [
    {
      title: 'Collapsible Group Item #1',
      description: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven\'t heard of them accusamus labore sustainable VHS.'
    },
    {
      title: 'Collapsible Group Item #2',
      description: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven\'t heard of them accusamus labore sustainable VHS.'
    },
    {
      title: 'Collapsible Group Item #3',
      description: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven\'t heard of them accusamus labore sustainable VHS.'
    }
  ];
  constructor(private route: ActivatedRoute, public el: ElementRef) {
  }
  @HostListener('window:scroll', ['$event'])
    checkScroll() {
      const componentPosition = this.el.nativeElement.offsetTop
      const scrollPosition = window.pageYOffset
      if (scrollPosition >= componentPosition) {
        this.state = 'show'
      } else {
        this.state = 'hide'
      }

    }
  ngOnInit(): void {
    console.log(this.itemBuilds);
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.route.params.subscribe((res: any) => {
      this.produto = this.itemBuilds.find(e => e.id === parseInt(res.id));  
      switch(parseInt(res.id)) { 
        case 541: { 
          this.varandas = true;
          this.buzios = false;
          this.solar = false;
          this.terranova = false;
          this.pousada = false;
          this.olimpia = false;
          break;
        }
        case 553: { 
          this.varandas = false;
          this.buzios = true;
          this.solar = false;
          this.terranova = false;
          this.pousada = false;
          this.olimpia = false;
          break;
        }
        case 6450: { 
          this.varandas = false;
          this.buzios = false;
          this.solar = true;
          this.terranova = false;
          this.pousada = false;
          this.olimpia = false;
          break;
        }        
        case 6451: { 
          this.varandas = false;
          this.buzios = false;
          this.solar = false;
          this.terranova = false;
          this.pousada = false;
          this.olimpia = true;
          break;
        }
        case 542: { 
          this.varandas = false;
          this.buzios = false;
          this.solar = false;
          this.terranova = true;
          this.pousada = false;
          this.olimpia = false;
          break;
        }
        case 543: { 
          this.varandas = false;
          this.buzios = false;
          this.solar = false;
          this.terranova = false;
          this.pousada = true;
          this.olimpia = false;
          break;
        }
      }
    });
  }  
  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

}
