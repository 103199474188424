import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GalaxPayService {

  apiURL = (environment.production) ? 'https://apis.wamvacationclub.com/prod/sendGalaxPay' : 'https://apis.wamvacationclub.com/prod/sendGalaxPay';

  constructor(
    private http: HttpClient
  ) { }

  apiGalax = 'https://app.galaxpay.com.br/webservice/';
  galaxId = (environment.production) ? '12187' : '5473';
  galaxHash = (environment.production) ? 'PyCgRhJ342UsHbY7QfM0PzUqAlXw5073145eIw0u' : '83Mw5u8988Qj6fZqS4Z8K7LzOo1j28S706R0BeFe';

  public loadAPIAppData(body: any): Observable<any> {
    return this.getGalaxData(body).pipe(map(
      res => {
        return {
          success: true,
          response: res
        };
      },
      err => {
        return {
          success: false,
          msg: err
        };
      }
    ));
  }

  public getGalaxData(body: any): Observable<any> {
    let httpHeaders: HttpHeaders;
    httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const endpoint = `${this.apiGalax}${body.action}`;
    const data = {
      Auth: {
        galaxId: this.galaxId,
        galaxHash: this.galaxHash
      },
      Request: body.payload
    };
    const options = {
      headers: httpHeaders
    };
    return this.http.post(endpoint, data, options);
  }

  public loadAPIAppData2(body: any): Observable<any> {
    try {
      // monta a url de acordo com a operação
      const urlApi = this.apiURL;
      // montando cabeçalho
      const awsApiKey = '5gmeNjQjx64BUWtHIm8jK9SUSaxNoM6F4Ozpejxk';
      let httpHeaders: HttpHeaders;
      httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': awsApiKey
      });
      const options = {
        headers: httpHeaders
      };
      body.environment = (environment.production) ? 'prod' : 'hml';
      // consultando API
      return this.http.post(urlApi, body, options);
    } catch (e) {
      console.error(e);
    }
  }

}
