<div class="container mt-5 container-first">
    <!-- DESCRICAO -->
    <div class="row home-section">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="prodDetail-title">{{produto.name}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="prodDetail-subTitle">{{produto.Endereco}}</p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row ">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="prodDetail-desc mb-0">{{produto.detalhe_6}}</p>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12">
                            <p class="prodDetail-desc1 mt-0 mb-0">{{produto.detalhe_7}}</p>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-12 ">
                            <p class="prodDetail-price mt-0 mb-0">R$ {{produto.Price}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 align-self-center d-none d-sm-block">
                    <button mdbBtn type="button" class="prodDetail-btn-saibaMais text-capitalize" routerLink="/comprar/{{produto.id}}" mdbWavesEffect>Comprar</button>
                </div>
            </div>
        </div>
    </div>
    
    <!-- GALERIA DESKTOP-->
    <div class="row mt-5 text-center d-none d-sm-flex">
        <div class="col-md-6 align-self-center text-right overflow-hidden prodDetail-galeriaCapa">
            <img src="{{produto.imagem_galeria_capa}}" class=" rounded" alt="..." (click)="galeria.show()"/>
        </div>
        <div class="col-md-6 align-self-center ">
            <div class="row">
                <div class="col-md-12 overflow-hidden prodDetail-galeriaLateral">
                    <img src="{{produto.imagem_galeria_horizontal}}" class="rounded" alt="..." (click)="galeria.show()"/>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6 pr-0 overflow-hidden prodDetail-galeriaLateral">
                    <img src="{{produto.imagem_galeria_sub_1}}" class="rounded" alt="..." (click)="galeria.show()"/>
                </div>
                <div class="col-md-6 pl-0 overflow-hidden prodDetail-galeriaLateral">
                    <img src="{{produto.imagem_galeria_sub_2}}" class="rounded" alt="..." (click)="galeria.show()"/>
                </div>
            </div>
        </div>
    </div>
    <!-- GALERIA MOBILE-->
    <div class="row mt-3 text-center d-block d-sm-none">
        <div class="col-12 align-self-center text-right">
            <div class="row mb-3">
                <div class="col-12">
                    <img src="{{produto.imagem_galeria_capa}}" class="img-fluid rounded" alt="..." (click)="galeria.show()"/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <img src="{{produto.imagem_galeria_horizontal}}" class="img-fluid rounded" alt="..." (click)="galeria.show()"/>
                </div>
            </div>
            <div class="row">
                <div class="col-6 ">
                    <img src="{{produto.imagem_galeria_sub_1}}" class="img-fluid rounded" alt="..." (click)="galeria.show()"/>
                </div>
                <div class="col-6 ">
                    <img src="{{produto.imagem_galeria_sub_2}}" class="img-fluid rounded" alt="..." (click)="galeria.show()"/>
                </div>
            </div>
        </div>
    </div>

    <div class="row d-block d-sm-none">
        <div class="col-12">
            <button mdbBtn type="button" class="prodDetail-btn-saibaMais text-capitalize m-0 mt-3" routerLink="/comprar/{{produto.id}}" mdbWavesEffect>Comprar</button>
        </div>
    </div>

    <!-- DETALHAMENTO -->
    <div class="row mt-5">
        <div class="row px-3">
            <div class="col-md-12">
                <p class="prodDetail-text-bold mb-0">
                    {{produto.text_title}}
                </p>
                <p class="prodDetail-text mt-0">    
                    {{produto.text_subTitle}}
                </p>
            </div>           
        </div>
        <div class="row">
            <div class="prodDetail-box mx-4 my-2">{{produto.Status}}</div>
            <div class="prodDetail-box mx-4 my-2 " *ngIf="produto.diferencial_2">{{produto.diferencial_2}}</div>
        </div>
    </div>
</div>
    <!-- ADICIONAIS -->
    <div class="prodDetail-section" id="vantagens">
        <div class="container mb-5 ">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <p class="prodDetail-title-section">Você pode aproveitar</p>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-3">
                    <div class="row mt-4 mt-md-0">
                        <div class="col-2 text-center" *ngIf="produto.aproveitar_1_text">
                            <div class="prodDetail-box-round pt-2">
                                <picture class="">
                                    <source srcset="{{produto.aproveitar_1_ico}}" type="image/svg+xml" />
                                    <img src="{{produto.aproveitar_1_ico}}" class="img-fluid" alt="..." />
                                </picture>
                            </div>
                        </div>
                        <div class="col-10 pl-5 pt-2 align-self-center" *ngIf="produto.aproveitar_1_text">
                            <p class="prodDetail-subTitle-section">{{produto.aproveitar_1_text}}</p>
                        </div>
                    </div>    
                    <div class="row mt-4">
                        <div class="col-2 text-center" *ngIf="produto.aproveitar_2_text">
                            <div class="prodDetail-box-round pt-2">
                                <picture class="">
                                    <source srcset="{{produto.aproveitar_2_ico}}" type="image/svg+xml" />
                                    <img src="{{produto.aproveitar_2_ico}}" class="img-fluid" alt="..." />
                                </picture>
                            </div>
                        </div>
                        <div class="col-10 pl-5 pt-2 align-self-center" *ngIf="produto.aproveitar_2_text">
                            <p class="prodDetail-subTitle-section">{{produto.aproveitar_2_text}}</p>
                        </div>
                    </div>     
                </div>
                <div class="col-md-3">
                    <div class="row mt-4 mt-md-0">
                        <div class="col-2 text-center" *ngIf="produto.aproveitar_3_text">
                            <div class="prodDetail-box-round pt-2">
                                <picture class="">
                                    <source srcset="{{produto.aproveitar_3_ico}}" type="image/svg+xml" />
                                    <img src="{{produto.aproveitar_3_ico}}" class="img-fluid" alt="..." />
                                </picture>
                            </div>
                        </div>
                        <div class="col-10 pl-5 pt-2 align-self-center" *ngIf="produto.aproveitar_3_text">
                            <p class="prodDetail-subTitle-section">{{produto.aproveitar_3_text}}</p>
                        </div>
                    </div>    
                    <div class="row mt-4">
                        <div class="col-2 text-center" *ngIf="produto.aproveitar_4_text">
                            <div class="prodDetail-box-round pt-2">
                                <picture class="">
                                    <source srcset="{{produto.aproveitar_4_ico}}" type="image/svg+xml" />
                                    <img src="{{produto.aproveitar_4_ico}}" class="img-fluid" alt="..." />
                                </picture>
                            </div>
                        </div>
                        <div class="col-10 pl-5 align-self-center" *ngIf="produto.aproveitar_4_text">
                            <p class="prodDetail-subTitle-section">{{produto.aproveitar_4_text}}</p>
                        </div>
                    </div>     
                </div>
                <div class="col-md-3">
                    <div class="row mt-4 mt-md-0">
                        <div class="col-2 text-center" *ngIf="produto.aproveitar_5_text">
                            <div class="prodDetail-box-round pt-2">
                                <picture class="">
                                    <source srcset="{{produto.aproveitar_5_ico}}" type="image/svg+xml" />
                                    <img src="{{produto.aproveitar_5_ico}}" class="img-fluid" alt="..." />
                                </picture>
                            </div>
                        </div>
                        <div class="col-10 pl-5 pt-2 align-self-center" *ngIf="produto.aproveitar_5_text">
                            <p class="prodDetail-subTitle-section">{{produto.aproveitar_5_text}}</p>
                        </div>
                    </div>    
                    <div class="row mt-4">
                        <div class="col-2 text-center">
                            <div class="prodDetail-box-round pt-2" *ngIf="produto.aproveitar_6_text">
                                <picture class="">
                                    <source srcset="{{produto.aproveitar_6_ico}}" type="image/svg+xml" />
                                    <img src="{{produto.aproveitar_6_ico}}" class="img-fluid" alt="..." />
                                </picture>
                            </div>
                        </div>
                        <div class="col-10 pl-5 align-self-center" *ngIf="produto.aproveitar_6_text">
                            <p class="prodDetail-subTitle-section">{{produto.aproveitar_6_text}}</p>
                        </div>
                    </div>     
                </div>
                <div class="col-md-3 d-none d-sm-block">
                    <div class="row">
                        <div class="col-md-2 pr-0 text-center" >
                            <picture class="home-product-check">
                                <source srcset="assets/check.svg" type="image/svg+xml" />
                                <img src="assets/check.svg" class="img-fluid" alt="..." />
                            </picture>
                        </div>
                        <div class="col-md-10 pl-0">
                            <p class="home-product-subTitle">{{produto.detalhe_1}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2 pr-0 text-center">
                            <picture>
                            <source srcset="assets/check.svg" type="image/svg+xml" />
                            <img src="assets/check.svg" class="img-fluid" alt="..." />
                            </picture>
                        </div>
                        <div class="col-md-10 pl-0">
                            <p class="home-product-subTitle">{{produto.detalhe_2}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2 pr-0 text-center">
                            <picture>
                            <source srcset="assets/check.svg" type="image/svg+xml" />
                            <img src="assets/check.svg" class="img-fluid" alt="..." />
                            </picture>
                        </div>
                        <div class="col-md-10 pl-0">
                            <p class="home-product-subTitle">{{produto.detalhe_3}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2 pr-0 text-center">
                            <picture>
                            <source srcset="assets/check.svg" type="image/svg+xml" />
                            <img src="assets/check.svg" class="img-fluid" alt="..." />
                            </picture>
                        </div>
                        <div class="col-md-10 pl-0">
                            <p class="home-product-subTitle">{{produto.detalhe_4}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2 pr-0 text-center">
                            <picture>
                            <source srcset="assets/check.svg" type="image/svg+xml" />
                            <img src="assets/check.svg" class="img-fluid" alt="..." />
                            </picture>
                        </div>
                        <div class="col-md-10 pl-0">
                            <p class="home-product-subTitle">{{produto.detalhe_5}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="container mt-5">
    <!-- LOCALIZACAO -->
    <div class="row mt-5" id="localizacao" style="display: contents;">
        <div class="row px-3">
            <div class="col-md-12">
                <p class="prodDetail-title-section">
                    Localização
                </p>
            </div>
            <div class="col-md-12">
                <p class="prodDetail-text">
                    {{produto.localizacao}}
                </p>
            </div>
            <div class="col-md-12" *ngIf="buzios">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.3233955981495!2d-41.928653785135594!3d-22.790477785071168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x97002f440a517d%3A0xbb4a382488f6eac7!2sBuzios%20Beach%20Resort!5e0!3m2!1spt-BR!2sbr!4v1594861597407!5m2!1spt-BR!2sbr"
                    width="100%"
                    height="250"
                    frameborder="0"
                    style="border: 0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
            </div>
            <div class="col-md-12" *ngIf="varandas">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3800.2419066638904!2d-48.635259249048474!3d-17.733237387812824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a7378839e341d5%3A0x117bf345f0138!2sVarandas%20Thermas%20Park!5e0!3m2!1spt-BR!2sbr!4v1588347541883!5m2!1spt-BR!2sbr"
                    width="100%"
                    height="250"
                    frameborder="0"
                    style="border: 0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
            </div>
            <div class="col-md-12" *ngIf="solar">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3732.183265011027!2d-48.919917!3d-20.7027814!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94bc6fa5da036b21%3A0x6685291276fd7372!2sSolar%20Das%20%C3%81guas!5e0!3m2!1spt-BR!2sbr!4v1594828060627!5m2!1spt-BR!2sbr"
                    width="100%"
                    height="250"
                    frameborder="0"
                    style="border: 0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
            </div>
            <div class="col-md-12" *ngIf="terranova">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15314.162668790634!2d-39.0119557!3d-16.3463978!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x736eb1b1bf1b091%3A0x4e8b375da71c6a7b!2sTerranova%20Ondas%20Resort!5e0!3m2!1spt-BR!2sbr!4v1588376244201!5m2!1spt-BR!2sbr"
                    width="100%"
                    height="250"
                    frameborder="0"
                    style="border: 0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
            </div>
            <div class="col-md-12" *ngIf="pousada">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.0688337763772!2d-45.57576168503763!3d-22.725682785104162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc89b2b8959841%3A0xaf6249c4ff04bacd!2sPousada%20L%C3%A0-Bas!5e0!3m2!1spt-BR!2sbr!4v1588380548090!5m2!1spt-BR!2sbr"
                    width="100%"
                    height="250"
                    frameborder="0"
                    style="border: 0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
            </div>
            <div class="col-md-12" *ngIf="olimpia">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.608635974986!2d-48.91802198459605!3d-20.726111086163204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94bc6e54b8d3b675%3A0x612c9e3720c3fe73!2sEnjoy%20Ol%C3%ADmpia%20Park%20Resort!5e0!3m2!1sen!2sbr!4v1596662410021!5m2!1sen!2sbr"
                    width="100%"
                    height="250"
                    frameborder="0"
                    style="border: 0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4" *ngIf="produto.maps_point_1 || produto.maps_point_2">
            <ul>
                <li class="prodDetail-text" *ngIf="produto.maps_point_1">{{produto.maps_point_1}}</li>
                <li class="prodDetail-text" *ngIf="produto.maps_point_2">{{produto.maps_point_2}}</li>
            </ul>
        </div>
        <div class="col-md-4" *ngIf="produto.maps_point_3 || produto.maps_point_4">
            <ul>
                <li class="prodDetail-text" *ngIf="produto.maps_point_3">{{produto.maps_point_3}}</li>
                <li class="prodDetail-text" *ngIf="produto.maps_point_4">{{produto.maps_point_4}}</li>
            </ul>  
        </div>
    </div>
    <!-- FICHA TÉCNICA -->
    <div class="row" id="ficha">
        <div class="col-md-12">
            <p class="prodDetail-title-section">
                Ficha técnica
            </p>
        </div>
    </div>

    <div class="row" *ngIf="varandas">
        <div class="col-md-12">
            <ngb-accordion [closeOthers]="false" #acc="ngbAccordion" class="accordion-form">
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(1)">
                            <span class="text-capitalize" >Apartamento fracionado</span>
                            <mdb-icon fas icon="angle-{{sign_1}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="conteudo">
                        Acabamentos impecáveis e todos os detalhes que você precisa para garantir o ambiente que mais combina
                        com o seu estilo de vida. Com apartamento mobiliado e equipado com todos os serviços de hotelaria.
                        <ul class="mt-2">
                            <li>46,15m²</li>
                            <li>R$ 19.900,00</li>
                            <li>84 parcelasa partir de R$ 222,62</li>
                            <li>7 dias de hospedagem por ano</li>
                            <li>Período de uso de acordo com o CRONOGRAMA DE USO COMPARTILHADO.</li>
                        </ul>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(2)">
                            <span class="text-capitalize" >Memorial descritivo</span>
                            <mdb-icon fas icon="angle-{{sign_2}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="conteudo">
                        Sua casa de férias com opções de lazer para você e para a sua família:
                        <ul class="mt-2">
                            <li>Port cochère</li>
                            <li>Sala de jogo</li>
                            <li>Espaço fitness</li>
                            <li>Sauna</li>
                            <li>Jogos infantis</li>
                            <li>Home cine</li>
                            <li>Bar</li>
                            <li>Espaço gourmet</li>
                            <li>Quadra poliesportiva</li>
                            <li>Piscina adulto</li>
                            <li>Piscina infantil</li>
                            <li>Piscina com borda infinita</li>
                            <li>Playground</li>
                            <li>Pizzaria com shows de massas</li>
                            <li>Mall</li>
                        </ul>
                        Decorações, equipamentos, acabamentos e mobiliários a serem entregues conforme memorial descritivo e
                        contrato de venda, que serão entregues ao final da aquisição.
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(3)">
                            <span class="text-capitalize" >Cronograma de uso</span>
                            <mdb-icon fas icon="angle-{{sign_3}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                    <div class="conteudo">
                        <ul>
                            <li>
                            Por ser uma casa de férias compartilhada, as datas de utilização são pré-estabelecidas, dessa forma
                            você consegue se planejar com antecedência melhorando ainda mais sua experiência, verifique o
                            Cronograma de Uso, que será entregue ao final da aquisição.
                            </li>
                            <li>
                            <b>Intercâmbio:</b> nesta modalidade seu período poderá ser substituído por outro em nossos
                            empreendimentos, podendo viajar em data diferente do seu cronograma de uso e até conhecer novos
                            destinos.
                            </li>
                            <li>
                            Principais destinos disponíveis:
                            <ul class="mt-2">
                                <li>Caldas Novas - GO;</li>
                                <li>Domingos Martins - ES;</li>
                                <li>Gramado -RS;</li>
                                <li>Búzios - RJ;</li>
                                <li>Campos do Jordão – SP;</li>
                                <li>Suzano - SP;</li>
                                <li>Olímpia - SP.</li>
                            </ul>
                            </li>
                        </ul>
                    </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Passaporte parques</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="conteudo">
                        <ul class="mt-2">
                            <li>
                            Para as suas férias ficarem ainda melhores, o seu contrato de multipropriedade, com o Varandas
                            Thermas Park, vem com mais vantagens: passaportes para utilização dos clubes Náutico e Clube Privé.
                            São passaportes para acesso gratuito aos clubes, válidos por 3 anos.
                            </li>
                            <li>
                            Com o seu contrato assinado, é só ir ao clube Náutico ou Privé e fazer as carteirinhas de
                            associados.
                            </li>
                            <li>Suas férias com muito mais diversão em família!</li>
                        </ul>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>

    <div class="row" *ngIf="buzios">
        <div class="col-md-12">
            <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" class="accordion-form">
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Apartamento fracionado</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="conteudo">
                        O clima de paz e tranquilidade fará você se sentir em casa. Nossas suítes são emolduradas por uma vista
                        de tirar o fôlego.
                        <ul class="mt-2">
                            <li>Apartamento de 35,16m²</li>
                            <li>1 Quarto</li>
                            <li>R$ 35.597,31</li>
                            <li>84 parcelas a partir de R$ 423,78</li>
                            <li>14 dias de hospedagem por ano</li>
                            <li>Período de uso de acordo com o CRONOGRAMA DE USO COMPARTILHADO.</li>
                        </ul>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Memorial descritivo</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      O Búzios Beach Resort é composto por uma mega estrutura:
                      <ul class="mt-2">
                        <li>Restaurantes</li>
                        <li>Piscina</li>
                        <li>Espaço Kids</li>
                        <li>Acesso à praia reservada</li>
                        <li>SPA</li>
                        <li>Sauna</li>
                        <li>Trapézio</li>
                        <li>Arco e Flecha</li>
                        <li>Academia</li>
                        <li>Sala de Jogos</li>
                        <li>Quadra de Areia</li>
                        <li>Pista de Skate</li>
                      </ul>
                      Contrato de venda será entregue ao final da aquisição.
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Cronograma de uso</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      <ul>
                        <li>
                          Por ser uma casa de férias compartilhada, as datas de utilização são pré-estabelecidas, dessa forma
                          você consegue se planejar com antecedência melhorando ainda mais sua experiência, verifique o
                          Cronograma de Uso, que será entregue ao final da aquisição.
                        </li>
                        <li>
                          <b>Intercâmbio:</b> nesta modalidade seu período poderá ser substituído por outro em nossos
                          empreendimentos, podendo viajar em data diferente do seu cronograma de uso e até conhecer novos
                          destinos.
                        </li>
                        <li>
                          Principais destinos disponíveis:
                          <ul class="mt-2">
                            <li>Caldas Novas - GO;</li>
                            <li>Domingos Martins - ES;</li>
                            <li>Gramado -RS;</li>
                            <li>Búzios - RJ;</li>
                            <li>Campos do Jordão – SP;</li>
                            <li>Suzano - SP;</li>
                            <li>Olímpia - SP.</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        </div>
    </div>

    <div class="row" *ngIf="solar">
        <div class="col-md-12">
            <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" class="accordion-form">
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Apartamento fracionado</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      O clima de paz e tranquilidade fará você se sentir em casa. Nossas suítes são emolduradas por uma vista
                      de tirar o fôlego.
                      <ul class="mt-2">
                        <li>Apartamento de 46,82 m²</li>
                        <li>1 Quarto</li>
                        <li>R$ 56.258,92</li>
                        <li>92 parcelas a partir de R$ 611,51</li>
                        <li>21 dias de hospedagem por ano</li>
                        <li>Período de uso de acordo com o CRONOGRAMA DE USO COMPARTILHADO.</li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Memorial descritivo</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      O Solar das Águas Park Resort será composto por uma mega estrutura de mais de 44 mil m²:
                      <ul class="mt-2">
                        <li>Restaurante Temático</li>
                        <li>Espaço Teen</li>
                        <li>Loja de Conveniência</li>
                        <li>Lanchonete</li>
                        <li>Café</li>
                        <li>Brinquedoteca</li>
                        <li>Play Kids</li>
                        <li>Restaurante</li>
                        <li>Academia</li>
                        <li>Cinema</li>
                        <li>Sala de Jogos</li>
                        <li>Sauna</li>
                        <li>Mini Golf</li>
                        <li>Sport Bar</li>
                        <li>Quadra de Areia</li>
                        <li>Quadra de Futebol</li>
                        <li>Quadra de Basquete</li>
                        <li>Pista de Skate</li>
                      </ul>
                      Decorações, equipamentos, acabamentos e mobiliários a serem entregues conforme memorial descritivo e
                      contrato de venda, que serão entregues ao final da aquisição.
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Cronograma de uso</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      <ul>
                        <li>
                          Por ser uma casa de férias compartilhada, as datas de utilização são pré-estabelecidas, dessa forma
                          você consegue se planejar com antecedência melhorando ainda mais sua experiência, verifique o
                          Cronograma de Uso, que será entregue ao final da aquisição.
                        </li>
                        <li>
                          <b>Intercâmbio:</b> nesta modalidade seu período poderá ser substituído por outro em nossos
                          empreendimentos, podendo viajar em data diferente do seu cronograma de uso e até conhecer novos
                          destinos.
                        </li>
                        <li>
                          Principais destinos disponíveis:
                          <ul class="mt-2">
                            <li>Caldas Novas - GO;</li>
                            <li>Domingos Martins - ES;</li>
                            <li>Gramado -RS;</li>
                            <li>Búzios - RJ;</li>
                            <li>Campos do Jordão – SP;</li>
                            <li>Suzano - SP;</li>
                            <li>Olímpia - SP.</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        </div>
    </div>

    <div class="row" *ngIf="terranova">
        <div class="col-md-12">
            <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" class="accordion-form">
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Apartamento fracionado</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      Abrace a paz proporcionada pela bela paisagem composta por uma orla tranquila, com o sol brilhando ao
                      alto e, no meio do mar, um banco de corais que forma piscinas naturais na maré baixa. Tudo isso com a
                      experiência e o know how que uma propriedade de lazer Terranova Ondas Resort pode te oferecer.
                      <ul class="mt-2">
                        <li>Apartamennto de 29,07m²</li>
                        <li>1 Quarto</li>
                        <li>R$ 23.656,88</li>
                        <li>84 parcelas a partir de R$ 267,34</li>
                        <li>7 dias de hospedagem por ano</li>
                        <li>Período de uso de acordo com o CRONOGRAMA DE USO COMPARTILHADO.</li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Memorial descritivo</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      O Terranova Ondas Resort será composto por uma mega estrutura de mais de 13 mil m²:
                      <ul class="mt-2">
                        <li>Varanda Bar</li>
                        <li>Bar</li>
                        <li>Piscinas</li>
                        <li>Restaurante</li>
                        <li>Brinquedoteca</li>
                        <li>Playground</li>
                        <li>Academia</li>
                      </ul>
                      Decorações, equipamentos, acabamentos e mobiliários a serem entregues conforme memorial descritivo e
                      contrato de venda, que serão entregues ao final da aquisição.
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Cronograma de uso</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      <ul>
                        <li>
                          Por ser uma casa de férias compartilhada, as datas de utilização são pré-estabelecidas, dessa forma
                          você consegue se planejar com antecedência melhorando ainda mais sua experiência, verifique o
                          Cronograma de Uso, que será entregue ao final da aquisição.
                        </li>
                        <li>
                          <b>Intercâmbio:</b> nesta modalidade seu período poderá ser substituído por outro em nossos
                          empreendimentos, podendo viajar em data diferente do seu cronograma de uso e até conhecer novos
                          destinos.
                        </li>
                        <li>
                          Principais destinos disponíveis:
                          <ul class="mt-2">
                            <li>Caldas Novas - GO;</li>
                            <li>Domingos Martins - ES;</li>
                            <li>Gramado -RS;</li>
                            <li>Búzios - RJ;</li>
                            <li>Campos do Jordão – SP;</li>
                            <li>Suzano - SP;</li>
                            <li>Olímpia - SP.</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        </div>
    </div>

    <div class="row" *ngIf="pousada">
        <div class="col-md-12">
            <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" class="accordion-form">
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Apartamento fracionado</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      O clima de paz e tranquilidade fará você se sentir em casa. Nossas suítes são emolduradas por uma vista
                      de tirar o fôlego.
                      <ul class="mt-2">
                        <li>Apartamento de 35,22m²</li>
                        <li>1 Quarto</li>
                        <li>R$ 26.946,39</li>
                        <li>84 parcelas a partir de R$ 332,70</li>
                        <li>7 dias de hospedagem por ano</li>
                        <li>Período de uso de acordo com o CRONOGRAMA DE USO COMPARTILHADO.</li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Memorial descritivo</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      O clima de paz e tranquilidade fará você se sentir em casa. Nossas suítes são emolduradas por uma vista
                      de tirar o fôlego:
                      <ul class="mt-2">
                        <li>Ambientes com vista para as montanhas;</li>
                        <li>Piso de madeira;</li>
                        <li>
                          Cozinha equipada com fogão, geladeira, microondas e utensílios; Sala com sofá, mesa e TV à cabo de
                          39'';
                        </li>
                        <li>Wi-Fi em todas as dependências;</li>
                        <li>Serviço de arrumação e enxoval exclusivo;</li>
                        <li>Cesta de café da manhã entregue em diretamente nas suítes;</li>
                        <li>Suíte Pet Friendly.</li>
                      </ul>
                      Decorações, equipamentos, acabamentos e mobiliários a serem entregues conforme memorial descritivo e
                      contrato de venda, que serão entregues ao final da aquisição.
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                <ng-template ngbPanelTitle >
                    <div class="col-md-12" (click)="toggleSign(4)">
                        <span class="text-capitalize" >Cronograma de uso</span>
                        <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                    </div>
                </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      <ul>
                        <li>
                          Por ser uma casa de férias compartilhada, as datas de utilização são pré-estabelecidas, dessa forma
                          você consegue se planejar com antecedência melhorando ainda mais sua experiência, verifique o
                          Cronograma de Uso, que será entregue ao final da aquisição.
                        </li>
                        <li>
                          <b>Intercâmbio:</b> nesta modalidade seu período poderá ser substituído por outro em nossos
                          empreendimentos, podendo viajar em data diferente do seu cronograma de uso e até conhecer novos
                          destinos.
                        </li>
                        <li>
                          Principais destinos disponíveis:
                          <ul class="mt-2">
                            <li>Caldas Novas - GO;</li>
                            <li>Domingos Martins - ES;</li>
                            <li>Gramado -RS;</li>
                            <li>Búzios - RJ;</li>
                            <li>Campos do Jordão – SP;</li>
                            <li>Suzano - SP;</li>
                            <li>Olímpia - SP.</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        </div>
    </div>

	<div class="row" *ngIf="olimpia">
        <div class="col-md-12">
            <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" class="accordion-form">
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Apartamento fracionado</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      Acabamentos impecáveis e todos os detalhes que você precisa para garantir o ambiente que mais combina com o seu estilo de vida. Com apartamento mobiliado e equipado com todos os serviços de hotelaria.
                      <ul class="mt-2">
                        <li>Apartamento de 46,25m²</li>
                        <li>1 Quarto</li>
                        <li>R$ 67.289,92</li>
                        <li>92 parcelas fixas de R$ 731,41</li>
                        <li>28 dias de hospedagem por ano</li>
                        <li>Período de uso de acordo com o CRONOGRAMA DE USO COMPARTILHADO.</li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                    <ng-template ngbPanelTitle >
                        <div class="col-md-12" (click)="toggleSign(4)">
                            <span class="text-capitalize" >Memorial descritivo</span>
                            <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                        </div>
                    </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      O Olímpia Park Resort é do tamanho dos seus sonhos! Possui 4 torres residenciais com apartamentos distribuídos em 17 pavimentos por torre. São 17 mil m2 de terreno com área de lazer completa:
						<ul class="mt-2">
							<li>Choperia</li>
							<li>Restaurantes</li>
							<li>Pizzaria</li>
							<li>Área de mesas da Pizzaria</li>
							<li>Bar Café</li>
							<li>Pergolado</li>
							<li>Salão de Jogos</li>
							<li>Cinemas</li>
							<li>Elevadores</li>
							<li>Espaço Múltiplo</li>
							<li>Lanchonete</li>
							<li>Sorveteria</li>
							<li>Hall</li>
							<li>Lan House</li>
							<li>SPA/Sauna</li>
							<li>Academia</li>
							<li>Jogos Infantis e Fraldário</li>
							<li>Jardins</li>
							<li>Parque Aquático</li>
							<li>Lojas</li>
							<li>Praça de Alimentação.</li>
						</ul>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel class="header">
                <ng-template ngbPanelTitle >
                    <div class="col-md-12" (click)="toggleSign(4)">
                        <span class="text-capitalize" >Cronograma de uso</span>
                        <mdb-icon fas icon="angle-{{sign_4}}" class="icon-accordion"></mdb-icon>
                    </div>
                </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="conteudo">
                      <ul>
                        <li>
                          Por ser uma casa de férias compartilhada, as datas de utilização são pré-estabelecidas, dessa forma
                          você consegue se planejar com antecedência melhorando ainda mais sua experiência, verifique o
                          Cronograma de Uso, que será entregue ao final da aquisição.
                        </li>
                        <li>
                          <b>Intercâmbio:</b> nesta modalidade seu período poderá ser substituído por outro em nossos
                          empreendimentos, podendo viajar em data diferente do seu cronograma de uso e até conhecer novos
                          destinos.
                        </li>
                        <li>
                          Principais destinos disponíveis:
                          <ul class="mt-2">
							<li>Caldas Novas - GO</li>
							<li>Domingos Martins - ES</li>
							<li>Gramado -RS</li>
							<li>Búzios - RJ</li>
							<li>Campos do Jordão – SP</li>
							<li>Suzano - SP</li>
							<li>Olímpia - SP</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        </div>
    </div>
    <!-- PLANTA -->
    <div class="row mt-5 mb-0 " *ngIf="!buzios && !olimpia">
        <div class="col-md-12">
            <p class="prodDetail-title-section mb-0">
                Plantas
            </p>
        </div>
        <div class="col-md-12">
            <p class="prodDetail-subTitle-section-blue">
                Conheça a plantas dos quartos
            </p>
        </div>
    </div>
    <div class="row mt-0" *ngIf="!buzios && !olimpia">

    </div>
    <div class="row text-center px-3" *ngIf="!buzios && !olimpia">
        <div class="col-md-12 align-self-center prodDetail-box-planta pr-0 ">
            <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
                <mdb-carousel-item >
                    <div class="view w-100">
                        <img
                            class="d-block w-100 "
                            src="{{produto.planta_1}}"
                            alt="planta_1"
                        />
                    </div>
                </mdb-carousel-item>
                <mdb-carousel-item >
                    <div class="view w-100">
                        <img
                            class="d-block w-100 "
                            src="{{produto.planta_2}}"
                            alt="planta_2"
                        />
                    </div>
                </mdb-carousel-item>
                
            </mdb-carousel>
        </div>
    </div>

    <!-- SEGUNDA GALERIA -->
    <div class="row mt-5 mb-0 " *ngIf="olimpia">
        <div class="col-md-12">
            <p class="prodDetail-title-section mb-0">
                Fotos do parque aquático
            </p>
        </div>
    </div>
    <!-- GALERIA DESKTOP-->
    <div class="row mt-5 text-center d-none d-sm-flex"  *ngIf="olimpia">
        <div class="col-md-6 align-self-center text-right overflow-hidden prodDetail-galeriaCapa">
            <img src="{{produto.imagem_galeria_sub_capa}}" class=" rounded" alt="..." (click)="parqueGaleria.show()"/>
        </div>
        <div class="col-md-6 align-self-center ">
            <div class="row">
                <div class="col-md-12 overflow-hidden prodDetail-galeriaLateral">
                    <img src="{{produto.imagem_galeria_sub_horizontal}}" class="rounded" alt="..." (click)="parqueGaleria.show()"/>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-6 pr-0 overflow-hidden prodDetail-galeriaLateral">
                    <img src="{{produto.imagem_galeria_sub_sub_1}}" class="rounded" alt="..." (click)="parqueGaleria.show()"/>
                </div>
                <div class="col-md-6 pl-0 overflow-hidden prodDetail-galeriaLateral">
                    <img src="{{produto.imagem_galeria_sub_sub_2}}" class="rounded" alt="..." (click)="parqueGaleria.show()"/>
                </div>
            </div>
        </div>
    </div>
    <!-- GALERIA MOBILE-->
    <div class="row mt-3 text-center d-block d-sm-none"  *ngIf="olimpia">
        <div class="col-12 align-self-center text-right">
            <div class="row mb-3">
                <div class="col-12">
                    <img src="{{produto.imagem_galeria_sub_capa}}" class="img-fluid rounded" alt="..." (click)="parqueGaleria.show()"/>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <img src="{{produto.imagem_galeria_sub_horizontal}}" class="img-fluid rounded" alt="..." (click)="parqueGaleria.show()"/>
                </div>
            </div>
            <div class="row">
                <div class="col-6 ">
                    <img src="{{produto.imagem_galeria_sub_sub_1}}" class="img-fluid rounded" alt="..." (click)="parqueGaleria.show()"/>
                </div>
                <div class="col-6 ">
                    <img src="{{produto.imagem_galeria_sub_sub_2}}" class="img-fluid rounded" alt="..." (click)="parqueGaleria.show()"/>
                </div>
            </div>
        </div>
    </div>
    <!-- CLUBE CIA -->
    <div class="row mt-5" id="clubecia">
        <div class="col-md-12">
            <p class="prodDetail-title-section mb-0">
                Club Cia 
            </p>
        </div>
        <div class="col-md-12">
            <p class="prodDetail-subTitle-section-blue">
                Viaje o Brasil e o mundo todo com a compra da sua casa de férias!
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <p class="prodDetail-subTitle-section">Vejas as várias formar de usar</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 text-center mt-3">
                    <picture class="">
                        <source srcset="assets/Money-Bag.svg" type="image/svg+xml" />
                        <img src="assets/Money-Bag.svg" class="img-fluid" alt="..." />
                    </picture>
                </div>
                <div class="col-md-10 pl-3 pt-2 align-self-center">
                    <p class="prodDetail-text">
                        <span class="prodDetail-text-bold">Monetização:</span> caso não utilize seu período de uso em um ano, é possível transferi-lo para o Club Cia, que irá atribuir um valor em dinheiro. Esse crédito pode ser utilizado para compra de diversos serviços oferecidos pelo Club Cia tais como passagens aéreas, hospedagens, seguro viagem, ingressos e passeios em diversos destinos. 
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 text-center mt-3">
                    <picture class="">
                        <source srcset="assets/keys.svg" type="image/svg+xml" />
                        <img src="assets/keys.svg" class="img-fluid" alt="..." />
                    </picture>
                </div>
                <div class="col-md-10 pl-3 pt-2 align-self-center">
                    <p class="prodDetail-text">
                        <span class="prodDetail-text-bold">Intercâmbio:</span> nesta modalidade seu período poderá ser substituído por outro em nossos empreendimentos, podendo viajar em data diferente do seu cronograma de uso e até conhecer novos destinos.  
                    </p>
                </div>
            </div>            
        </div>
        <div class="col-md-6 d-none d-sm-block">
            <img src="assets/header_desktop.png" class="img-fluid" alt="..." />
        </div>
    </div>
    <!-- DESCRICAO FLUTUANTE -->
    <div class="row mt-5"></div>
    
    <!-- GALERIA PRINCIPAL-->
    <div mdbModal #galeria="mdbModal" class="modal right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fluid" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row prodDetail-modal-header">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="prodDetail-title-black">{{produto.name}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="prodDetail-subTitle">{{produto.Endereco}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-right">
                            <button type="button" class="prodDetail-box-galeria" aria-label="Close" (click)="galeria.hide()">
                                <span aria-hidden="true">X Fechar</span>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
                            <mdb-carousel-item>
                                <div class="view w-100">
                                    <img
                                        class="d-block w-100 rounded"
                                        src="{{produto.imagem_galeria_capa}}"
                                        alt="imagem_galeria_capa"
                                    />
                                </div>
                            </mdb-carousel-item>
                            <mdb-carousel-item>
                                <div class="view w-100">
                                    <img
                                        class="d-block w-100 rounded"
                                        src="{{produto.imagem_galeria_horizontal}}"
                                        alt="imagem_galeria_horizontal"
                                    />
                                </div>
                            </mdb-carousel-item>
                            <mdb-carousel-item>
                                <div class="view w-100">
                                    <img
                                        class="d-block w-100 rounded"
                                        src="{{produto.imagem_galeria_sub_1}}"
                                        alt="imagem_galeria_sub_1"
                                    />
                                </div>
                            </mdb-carousel-item>
                            <mdb-carousel-item>
                                <div class="view w-100 rounded">
                                    <img
                                        class="d-block w-100 "
                                        src="{{produto.imagem_galeria_sub_2}}"
                                        alt="imagem_galeria_sub_2"
                                    />
                                </div>
                            </mdb-carousel-item>
                        </mdb-carousel>
                    </div>
                </div>
            </div>
        </div >
    </div >
    
    <!--GALERIA SECUNDÁRIA-->
    <div mdbModal #parqueGaleria="mdbModal" class="modal right" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fluid" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row prodDetail-modal-header">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="prodDetail-title-black">{{produto.name}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="prodDetail-subTitle">{{produto.Endereco}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-right">
                            <button type="button" class="prodDetail-box-galeria" aria-label="Close" (click)="parqueGaleria.hide()">
                                <span aria-hidden="true">X Fechar</span>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
                            <mdb-carousel-item>
                                <div class="view w-100 rounded">
                                    <img
                                        class="d-block w-100 rounded"
                                        src="{{produto.imagem_galeria_sub_capa}}"
                                        alt="imagem_galeria_sub_capa"
                                    />
                                </div>
                            </mdb-carousel-item>
                            <mdb-carousel-item>
                                <div class="view w-100 rounded">
                                    <img
                                        class="d-block w-100 "
                                        src="{{produto.imagem_galeria_sub_horizontal}}"
                                        alt="imagem_galeria_sub_horizontal"
                                    />
                                </div>
                            </mdb-carousel-item>
                            <mdb-carousel-item>
                                <div class="view w-100 rounded">
                                    <img
                                        class="d-block w-100 "
                                        src="{{produto.imagem_galeria_sub_sub_1}}"
                                        alt="imagem_galeria_sub_sub_1"
                                    />
                                </div>
                            </mdb-carousel-item>
                            <mdb-carousel-item>
                                <div class="view w-100 rounded">
                                    <img
                                        class="d-block w-100 "
                                        src="{{produto.imagem_galeria_sub_sub_2}}"
                                        alt="imagem_galeria_sub_sub_2"
                                    />
                                </div>
                            </mdb-carousel-item>
                        </mdb-carousel>
                    </div>
                </div>
            </div>
        </div >
    </div >

</div>


<div class="row main-footer px-sm-5 pt-sm-4" [@scrollAnimation]="state">
    <div class="col-12 prodDetail-footer-main">
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-md-12">
                        <p class="prodDetail-title-black">{{produto.name}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="prodDetail-subTitle">{{produto.Endereco}}</p>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row ">
                    <div class="col-md-6">
                        <div class="row d-none d-sm-block">
                            <div class="col-md-12">
                                <p class="prodDetail-desc mb-0">{{produto.detalhe_6}} </p>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-12 px-0 px-md-3">
                                <p class="prodDetail-desc1 mt-0 mb-0 d-none d-sm-block">{{produto.detalhe_8}}</p>
                                <p class="prodDetail-desc1 mt-0 mb-0 d-block d-sm-none">{{produto.detalhe_7}}</p>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-md-12 ">
                                <p class="prodDetail-price mt-0">R$ {{produto.Price}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 align-self-center d-none d-sm-block">
                        <button mdbBtn type="button" class="prodDetail-btn-saibaMais text-capitalize" routerLink="/comprar/{{produto.id}}" mdbWavesEffect>Comprar</button>
                    </div>
                </div>
            </div>
            <div class="col-12 d-block d-sm-none ">
                <button mdbBtn type="button" class="prodDetail-btn-saibaMais text-capitalize mx-0" routerLink="/comprar/{{produto.id}}" mdbWavesEffect>Comprar</button>
            </div>
        </div>
    </div>

</div>

