import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-produto-detail',
  templateUrl: './menu-produto-detail.component.html',
  styleUrls: ['./menu-produto-detail.component.scss']
})
export class MenuProdutoDetailComponent implements OnInit {
  itemMenus = [
    {"id":1,"name":"Vantagens","url":"vantagens"},
    {"id":2,"name":"Localização","url":"localizacao"},
    {"id":3,"name":"Ficha Técnica","url":"ficha"},
    {"id":4,"name":"Clube Cia","url":"clubecia"}
  ];;
  itemCarousels = [
    {"id":1,"name":"image1","url":"assets/0.jpg"},
    {"id":2,"name":"image2","url":"assets/1.jpg"},
    {"id":3,"name":"image3","url":"assets/2.jpg"},
    {"id":4,"name":"image4","url":"assets/3.jpg"}
  ];;
  itemStates = [
    {"id":1,"name":"São Paulo","place":"São Paulo - SP","url":"#"},
    {"id":2,"name":"Rio de Janeiro","place":"Búzios - RJ","url":"#"},
    {"id":3,"name":"Bahia","place":"Porto Seguro - BA","url":"#"},
    {"id":4,"name":"Goiás","place":"Caldas Novas - GO","url":"#"},
    {"id":5,"name":"Todos","place":"Campos do Jordão - SP","url":"#"}
  ];;
  itemBuilds = [
    {"id":1,"name":"Varandas Thermas Park","Image":"assets/Varandas.jpg","Endereco":"Caldas Novas - GO","Diferencial":"Com parque aquático","Status":"Entrega em Junho/2022","Price":"236,90"}
  ];;
  constructor() { }

  ngOnInit(): void {
  }

}
