<div class="container mt-5 mb-md-5 mb-0 container-first">
    <div class="row home-section">
        <div class="col-md-3">
            <div class="formVenda-box px-md-2 mt-3 d-none d-sm-block">
                <div class="row ">
                    <div class="col-md-12 my-3 home-align-center">
                        <img src="{{produto.Image}}" class="img-fluid rounded" alt="..." />
                    </div>
                </div>
                <div class="row ml-1">
                    <div class="col-md-12 ">
                        <p class="formVenda-title mb-0">{{produto.name}}</p>
                    </div>
                </div>
                <div class="row ml-1">
                    <div class="col-md-12">
                        <p class="formVenda-subTitle mt-0">{{produto.Endereco}}</p>
                    </div>
                </div>
                <div class="row ml-1">
                    <div class="col-md-12">
                        <p class="formVenda-desc mb-0">{{produto.detalhe_6}}</p>
                        <span class="formVenda-desc1 mt-0">{{produto.detalhe_7}}</span>
                    </div>
                </div>
                <div class="row ml-1">
                    <div class="col-md-12">
                        <p class="formVenda-price">R$ {{produto.Price}}</p>
                    </div>
                </div>
            </div>
            <div class="formVenda-box px-md-2 mt-3 d-block d-sm-none">
                <ngb-accordion [closeOthers]="false" #acc="ngbAccordion" class="accordion-form" activeIds="ngb-panel-0">
                    <ngb-panel class="header">
                        <ng-template ngbPanelContent>
                            <div class="row ">
                                <div class="col-md-12 my-3 home-align-center">
                                    <img src="{{produto.Image}}" class="img-fluid rounded" alt="..." />
                                </div>
                            </div>
                            <div class="row ml-1">
                                <div class="col-md-12 ">
                                    <p class="formVenda-title mb-0">{{produto.name}}</p>
                                </div>
                            </div>
                            <div class="row ml-1">
                                <div class="col-md-12">
                                    <p class="formVenda-subTitle mt-0">{{produto.Endereco}}</p>
                                </div>
                            </div>
                            <div class="row ml-1">
                                <div class="col-md-12">
                                    <p class="formVenda-desc mb-0">{{produto.detalhe_6}}</p>
                                    <span class="formVenda-desc1 mt-0">{{produto.detalhe_7}}</span>
                                </div>
                            </div>
                            <div class="row ml-1">
                                <div class="col-md-12">
                                    <p class="formVenda-price">R$ {{produto.Price}}</p>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelTitle >
                            <div class="row ">
                                <div class="col-md-12">
                                    <p class="formVenda-desc mb-0">{{produto.detalhe_6}}</p>
                                    <span class="formVenda-desc1 mt-0">{{produto.detalhe_7}}</span>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <p class="formVenda-price">R$ {{produto.Price}}</p>
                                </div>
                            </div>
                            <div class="col-md-12" (click)="toggleSign(1)">
                                <mdb-icon fas icon="angle-{{sign_1}}" class="icon-accordion"></mdb-icon>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <div class="col-md-9 formVenda-box-form">
            <form role="form" #contact="ngForm" (ngSubmit)="submitContactData(contact)">
                <input type="hidden" name="contrato_centroCusto" [(ngModel)]="contato.contrato.centroCusto">
                <input type="hidden" name="contrato_unidade" [(ngModel)]="contato.contrato.unidade">
                <input type="hidden" name="contrato_cota" [(ngModel)]="contato.contrato.cota">
                <input type="hidden" name="comprador_nome" [(ngModel)]="contato.comprador.nome">
                <input type="hidden" name="comprador_dataNascimento" [(ngModel)]="contato.comprador.dataNascimento">
                <input type="hidden" name="comprador_nacionalidade" [(ngModel)]="contato.comprador.nacionalidade">
                <input type="hidden" name="comprador_estadoCivil" [(ngModel)]="contato.comprador.estadoCivil">
                <input type="hidden" name="comprador_profissao" [(ngModel)]="contato.comprador.profissao">
                <input type="hidden" name="comprador_rg" [(ngModel)]="contato.comprador.rg">
                <input type="hidden" name="comprador_orgaoExpedidor" [(ngModel)]="contato.comprador.orgaoExpedidor">
                <input type="hidden" name="comprador_cpf" [(ngModel)]="contato.comprador.cpf">
                <input type="hidden" name="contato_telefone1" [(ngModel)]="contato.contato.telefone1">
                <input type="hidden" name="contato_telefone2" [(ngModel)]="contato.contato.telefone2">
                <input type="hidden" name="contato_email" [(ngModel)]="contato.contato.email">
                <input type="hidden" name="conjuge_nome" [(ngModel)]="contato.conjuge.nome">
                <input type="hidden" name="conjuge_dataNascimento" [(ngModel)]="contato.conjuge.dataNascimento">
                <input type="hidden" name="conjuge_nacionalidade" [(ngModel)]="contato.conjuge.nacionalidade">
                <input type="hidden" name="conjuge_regimeCasamento" [(ngModel)]="contato.conjuge.regimeCasamento">
                <input type="hidden" name="conjuge_profissao" [(ngModel)]="contato.conjuge.profissao">
                <input type="hidden" name="conjuge_rg" [(ngModel)]="contato.conjuge.rg">
                <input type="hidden" name="conjuge_orgaoExpedidor" [(ngModel)]="contato.conjuge.orgaoExpedidor">
                <input type="hidden" name="conjuge_cpf" [(ngModel)]="contato.conjuge.cpf">
                <input type="hidden" name="conjuge_email" [(ngModel)]="contato.conjuge.email">
                <input type="hidden" name="endereco_cep" [(ngModel)]="contato.endereco.cep">
                <input type="hidden" name="endereco_logradouro" [(ngModel)]="contato.endereco.logradouro">
                <input type="hidden" name="endereco_numero" [(ngModel)]="contato.endereco.numero">
                <input type="hidden" name="endereco_complemento" [(ngModel)]="contato.endereco.complemento">
                <input type="hidden" name="endereco_bairro" [(ngModel)]="contato.endereco.bairro">
                <input type="hidden" name="endereco_cidade" [(ngModel)]="contato.endereco.cidade">
                <input type="hidden" name="endereco_uf" [(ngModel)]="contato.endereco.uf">
                <input type="hidden" name="testemunha_nome" [(ngModel)]="contato.testemunha.nome">
                <input type="hidden" name="testemunha_email" [(ngModel)]="contato.testemunha.email">
                <input type="hidden" name="documentos_rg" [(ngModel)]="contato.documentos.rg">
                <input type="hidden" name="documentos_cpf" [(ngModel)]="contato.documentos.cpf">
                <input type="hidden" name="documentos_rgConjuge" [(ngModel)]="contato.documentos.rgConjuge">
                <input type="hidden" name="documentos_cpfConjuge" [(ngModel)]="contato.documentos.cpfConjuge">
                <input type="hidden" name="pagamento_number" [(ngModel)]="contato.pagamento.number" />
                <input type="hidden" name="pagamento_holder" [(ngModel)]="contato.pagamento.holder" />
                <input type="hidden" name="pagamento_expiryMonth" [(ngModel)]="contato.pagamento.expiryMonth" />
                <input type="hidden" name="pagamento_expiryYear" [(ngModel)]="contato.pagamento.expiryYear" />
                <input type="hidden" name="pagamento_cvv" [(ngModel)]="contato.pagamento.cvv" />
                <input type="hidden" name="pagamento_brand" [(ngModel)]="contato.pagamento.brand" />
                <input type="hidden" name="pagamento_parcelas" [(ngModel)]="contato.pagamento.numParcelas" />
                <!--FORM TITLE-->
                <div class="row mt-3 d-none d-sm-block">
                    <div class="col-md-12  mb-0">
                        <p class="formVenda-title-form mb-0">
                            Formulário de Aquisição de Propriedade
                        </p>
                    </div>
                </div>
                <!--LINE-->
                <div class="row d-none d-sm-block">
                    <div class="col-md-12">
                        <hr />
                    </div>
                </div>
                <!--CONTATOS-->
                <div class="row mt-4 mt-md-5">
                    <div class="col-md-12">
                        <p class="formVenda-title-form-subTitle my-0">Contatos</p>
                    </div>
                </div>
                <!--CONTATOS-->
                <div class="row">
                    <div class="col-md-6 pr-md-1">
                        <label for="formNome">Nome</label>
                        <input name="nomeContato" [(ngModel)]="contato.comprador.nome" class="form-control" placeholder="Nome" type="text" autocomplete="off" required/>
                    </div>
                    <div class="col-md-6 pl-md-1">
                        <label for="formEmail">Email</label>
                        <input name="emailContato" [(ngModel)]="contato.contato.email" class="form-control" placeholder="E-mail" type="email" autocomplete="off"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 pr-md-1">
                        <label for="formTel1">Telefone de Contato 1</label>
                        <input name="telefone1Contato" [(ngModel)]="contato.contato.telefone1" class="form-control" placeholder="Telefone" type="tel" [mask]="phoneMask" (keyup)="phoneChange($event)" autocomplete="off"/>
                    </div>
                    <div class="col-md-6 pl-md-1">
                        <label for="formTel2">Telefone de Contato 2</label>
                        <input name="telefone2Contato" [(ngModel)]="contato.contato.telefone2" class="form-control" placeholder="Telefone contato" type="tel" [mask]="phoneMask1" (keyup)="phoneChange1($event)" autocomplete="off"/>
                    </div>
                </div>
                <div class="row mt-3 mt-sm-0">
                    <!--DESKTOP-->
                    <div class="col-md-12 text-right d-none d-sm-block">
                        <button mdbBtn type="button" class="btn-formVenda-next text-capitalize px-5 py-3"
                            (click)="saveLead()" mdbWavesEffect [disabled]="leadSaved">
                            Próximo
                        </button>
                    </div>
                    <!--MOBILE-->
                    <div class="col-md-12 d-sm-none">
                        <button mdbBtn type="button" class="btn-formVenda-next text-capitalize px-5 py-3 col-12 m-0"
                            (click)="saveLead()" mdbWavesEffect>
                            Próximo
                        </button>
                    </div>
                </div>
                <!--DADOS PESSOAIS-->
                <div class="row mt-4 mt-md-5">
                    <div class="col-md-12">
                        <p class="formVenda-title-form-subTitle" [ngClass]="{'formVenda-disable': !leadSaved}">Dados Pessoais</p>
                    </div>
                </div>
                <!--DADOS PESSOAIS-->
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formNome">Nome</label>
                        <input name="nome" required [(ngModel)]="contato.comprador.nome" class="form-control" placeholder="Nome" type="text" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-3 px-md-1">
                        <label for="formDataNasc">Data de Nascimento</label>
                        <input name="dataNascimento" required [(ngModel)]="contato.comprador.dataNascimento" class="form-control" placeholder="Data de nascimento" type="date" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-3 pl-md-1">
                        <label for="formEmail">E-mail</label>
                        <input name="email" required [(ngModel)]="contato.contato.email" class="form-control" placeholder="E-mail" type="email" autocomplete="off"
                            [disabled]="!leadSaved" />
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formNac">Nacionalidade</label>
                        <input name="nacionalidade" required [(ngModel)]="contato.comprador.nacionalidade" class="form-control" placeholder="Nacionalidade" type="text" autocomplete="off"
                            [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-6 pl-md-1">
                        <div class="form-group">
                            <label for="formCivil">Estado Civil</label>
                            <select name="estadoCivil" required [(ngModel)]="contato.comprador.estadoCivil"
                                class="form-control browser-default custom-select" [disabled]="!leadSaved">
                                <option value="" disabled selected>Estado Civil</option>
                                <option value="Solteiro">Solteiro</option>
                                <option value="Casado">Casado</option>
                                <option value="União Estável">União Estável</option>
                                <option value="Divorciado">Divorciado</option>
                                <option value="Viuvo">Viúvo</option>
                                <option value="Separado Judicialmente">Separado Judicialmente</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formProf">Profissão</label>
                        <input name="profissao" required [(ngModel)]="contato.comprador.profissao" class="form-control" placeholder="Profissão" type="text" autocomplete="off"
                            [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-3 px-md-1">
                        <label for="formRG">RG</label>
                        <input name="rg" required [(ngModel)]="contato.comprador.rg" class="form-control" placeholder="RG" type="tel" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-3 pl-md-1">
                        <label for="formOrg">Orgão Expeditor</label>
                        <input name="orgaoExpedidor" required [(ngModel)]="contato.comprador.orgaoExpedidor" class="form-control" placeholder="Órgao expedidor" type="text" autocomplete="off"
                            [disabled]="!leadSaved" />
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-4 pr-md-1">
                        <label for="formCPF">CPF</label>
                        <input name="cpf" required [(ngModel)]="contato.comprador.cpf" class="form-control" placeholder="CPF" type="tel" mask="000.000.000-00" autocomplete="off" [disabled]="!leadSaved"/>
                    </div>
                    <div class="col-md-4 px-md-1">
                        <label for="formTel1">Telefone de Contato 1</label>
                        <input name="telefone1" required [(ngModel)]="contato.contato.telefone1" class="form-control" placeholder="Telefone" type="tel" [mask]="phoneMask" (keyup)="phoneChange($event)" autocomplete="off"
                            [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-4 pl-md-1">
                        <label for="formTel2">Telefone de Contato 2</label>
                        <input name="telefone2" [(ngModel)]="contato.contato.telefone2" class="form-control" placeholder="Telefone contato" type="tel" [mask]="phoneMask1" (keyup)="phoneChange1($event)" autocomplete="off"
                            [disabled]="!leadSaved" />
                    </div>
                </div>
                <!--DADOS CONJUGUE-->
                <div class="row mt-4 mt-md-5">
                    <div class="col-md-12">
                        <p class="formVenda-title-form-subTitle" [ngClass]="{'formVenda-disable': contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'}">Dados Cônjugue</p>
                    </div>
                </div>
                <!--DADOS CONJUGUE-->
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formNome">Nome</label>
                        <input name="nomeConjuge" required [(ngModel)]="contato.conjuge.nome" class="form-control" placeholder="Nome" type="text" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                    <div class="col-md-3 px-md-1">
                        <label for="formDataNasc">Data de Nascimento</label>
                        <input name="dataNascimentoConjuge" required [(ngModel)]="contato.conjuge.dataNascimento" class="form-control" placeholder="Data de nascimento" type="date" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                    <div class="col-md-3 pl-md-1">
                        <label for="formEmail">E-mail</label>
                        <input name="emailConjuge" required [(ngModel)]="contato.conjuge.email" class="form-control" placeholder="E-mail" type="email" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formNac">Nacionalidade</label>
                        <input name="nacionalidadeConjuge" required [(ngModel)]="contato.conjuge.nacionalidade" class="form-control" placeholder="Nacionalidade" type="text" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                    <div class="col-md-6 pl-md-1">
                        <div class="form-group">
                            <label for="formCivil">Estado Civil</label>
                            <select name="regimeCasamento" required [(ngModel)]="contato.conjuge.regimeCasamento" class="form-control browser-default custom-select" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'">
                                <option value="" disabled selected>Regime casamento</option>
                                <option value="Comunhão parcial">Comunhão parcial</option>
                                <option value="Comunhão universal">Comunhão universal</option>
                                <option value="Participação final nos aqüestos">Participação final nos aqüestos</option>
                                <option value="Separação de bens">Separação de bens</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formProf">Profissão</label>
                        <input name="profissaoConjuge" required [(ngModel)]="contato.conjuge.profissao" class="form-control" placeholder="Profissão" type="text" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                    <div class="col-md-3 px-md-1">
                        <label for="formRG">RG</label>
                        <input name="rgConjuge" required [(ngModel)]="contato.conjuge.rg" class="form-control" placeholder="RG" type="tel" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                    <div class="col-md-3 pl-md-1">
                        <label for="formOrg">Orgão Expeditor</label>
                        <input name="orgaoExpedidorConjuge" required [(ngModel)]="contato.conjuge.orgaoExpedidor" class="form-control" placeholder="Órgao expedidor" type="text" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-4 pr-md-1">
                        <label for="formCPF">CPF</label>
                        <input name="cpfConjuge" required [(ngModel)]="contato.conjuge.cpf" class="form-control" placeholder="CPF" type="tel" mask="000.000.000-00" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                    <div class="col-md-4 px-md-1">
                        <label for="formTel1">Telefone de Contato 1</label>
                        <input name="telefone1Conjuge" required [(ngModel)]="contato.conjuge.telefone1" class="form-control" placeholder="Telefone" type="tel" [mask]="phoneMask" (keyup)="phoneChange($event)" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                    <div class="col-md-4 pl-md-1">
                        <label for="formTel2">Telefone de Contato 2</label>
                        <input name="telefone2Conjuge" required [(ngModel)]="contato.conjuge.telefone2" class="form-control" placeholder="Telefone" type="tel" [mask]="phoneMask" (keyup)="phoneChange($event)" autocomplete="off" [disabled]="contato.comprador.estadoCivil != 'Casado' && contato.comprador.estadoCivil != 'União Estável'"/>
                    </div>
                </div>
                <!--ENDERECO-->
                <div class="row mt-4 mt-md-5">
                    <div class="col-md-12">
                        <p class="formVenda-title-form-subTitle" [ngClass]="{'formVenda-disable': !leadSaved}">Endereço</p>
                    </div>
                </div>
                <!--ENDERECO-->
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formCEP">CEP</label>
                        <div class="input-group input-group-alternative">
                            <input name="cep" required [(ngModel)]="contato.endereco.cep" class="form-control" placeholder="CEP" mask="00000-000" aria-describedby="cepHelp" type="tel" autocomplete="off" (keyup)="buscaCEPData($event.target.value)" [disabled]="!leadSaved"/>
                            <div *ngIf="carregandoCEP" class="input-group-append">
                                <span class="input-group-text" id="basic-addon2">
                                <div class="loader">.</div>
                                </span>
                            </div>
                        </div>
                        <small *ngIf="cepinvalido" id="cepHelp" class="form-text text-danger">CEP inválido.</small>
                    </div>
                    <div class="col-md-6 pl-md-1">
                        <label for="formLog">Logadouro</label>
                        <input name="logradouro" required [(ngModel)]="contato.endereco.logradouro" [readonly]="cep.logradouro != null" class="form-control" placeholder="Logradouro" type="text" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-4 pr-md-1">
                        <label for="formNum">Número</label>
                        <input name="numero" required [(ngModel)]="contato.endereco.numero" class="form-control" placeholder="Número" type="text" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-4 px-md-1">
                        <label for="formComp">Complemento</label>
                        <input name="complemento" [(ngModel)]="contato.endereco.complemento" class="form-control" placeholder="Complemento" type="text" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-4 pl-md-1">
                        <label for="formBairro">Bairro</label>
                        <input name="bairro" required [(ngModel)]="contato.endereco.bairro" class="form-control" [readonly]="cep.bairro != null" placeholder="Bairro" type="text" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formCid">Cidade</label>
                        <input name="cidade" required [(ngModel)]="contato.endereco.cidade" class="form-control" [readonly]="cep.localidade != null" placeholder="Cidade" type="text" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-6 pl-md-1">
                        <label for="formUF">UF</label>
                        <select name="uf" required [(ngModel)]="contato.endereco.uf" class="form-control browser-default custom-select" [disabled]="cep.uf != null" [disabled]="!leadSaved">
                            <option value="" disabled selected>UF</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                    </div>
                </div>
                <!--CARTÃO-->
                <div class="row mt-4 mt-md-5">
                    <div class="col-md-12">
                        <p class="formVenda-title-form-subTitle" [ngClass]="{'formVenda-disable': !leadSaved}">Entrada</p>
                    </div>
                </div>
                <!--CARTÃO-->
                <div class="row ">
                    <div class="col-md-6 pr-md-1">
                        <label for="formValor">Valor de Entrada</label>
                        <input name="valorCompra" [(ngModel)]="contato.pagamento.valorEntradaStr" class="form-control" readonly type="text" autocomplete="off" value="R$ {{produto.Price}}"
                            disabled />
                    </div>
                    <div class="col-md-6 pl-md-1">
                        <label for="formNumCard">Número do cartão de crédito</label>
                        <div class="input-group input-group-alternative">
                            <div class="input-group-prepend">
                                <span *ngIf="cardBrand" class="input-group-text" id="basic-addon1" [innerHTML]="cardBrand.icon"></span>
                                <span *ngIf="!cardBrand" class="input-group-text" id="basic-addon1">
                                    <i class="far fa-credit-card"></i>
                                </span>
                            </div>
                            <input name="numeroCartao" [readonly]="contato.pagamento.valorEntrada <= 0" required [(ngModel)]="contato.pagamento.number" class="form-control" aria-describedby="nomeCardHelp" placeholder="Número do cartão de crédito" type="text" autocomplete="off" mask="0000 0000 0000 0000" (keyup)="detectCardType($event.target.value)" [disabled]="!leadSaved"/>
                        </div>
                        <small id="nomeCardHelp" class="form-text text-muted">O cartão informado deve estar vinculado com o CPF do comprador.</small>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-12">
                        <label for="formNomCard">Nome impresso no cartão</label>
                        <input name="nomeCartao" [readonly]="contato.pagamento.valorEntrada <= 0" required [(ngModel)]="contato.pagamento.holder" class="form-control" placeholder="Nome impresso no cartão" type="text" autocomplete="off" (keyup)="contato.pagamento.holder = contato.pagamento.holder.toUpperCase()" [disabled]="!leadSaved"/>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3 pr-md-1">
                        <label for="formMesCard">Mês</label>
                        <select name="vencimentoCartao" required [(ngModel)]="contato.pagamento.expiryMonth" class="form-control browser-default custom-select" [disabled]="contato.pagamento.valorEntrada <= 0" [disabled]="!leadSaved">
                            <option value="" disabled selected>Mês</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </div>
                    <div class="col-md-3 px-md-1">
                        <label for="formAnoCard">Ano</label>
                        <select name="vencimentoCartaoAno" required [(ngModel)]="contato.pagamento.expiryYear" class="form-control browser-default custom-select" [disabled]="contato.pagamento.valorEntrada <= 0" [disabled]="!leadSaved">
                            <option value="" disabled selected>Ano</option>
                            <option *ngFor="let year of listYears" [value]="year">{{ year }}</option>
                        </select>
                    </div>
                    <div class="col-md-3 px-md-1">
                        <label for="formCodCard">Código de Segurança</label>
                        <input name="cvvCartao" [readonly]="contato.pagamento.valorEntrada <= 0" required [(ngModel)]="contato.pagamento.cvv" class="form-control" placeholder="Código de segurança" mask="000" type="text" autocomplete="off" [disabled]="!leadSaved" />
                    </div>
                    <div class="col-md-3 pl-md-1">
                        <label for="formBandCard">Bandeira</label>
                        <select name="bandeiraCartao" required [(ngModel)]="contato.pagamento.brand" class="form-control browser-default custom-select" (ngModelChange)="changeCreditCard($event)" [disabled]="contato.pagamento.valorEntrada <= 0" [disabled]="!leadSaved">
                            <option value="" disabled selected>Bandeira</option>
                            <option *ngFor="let brand of brands" [value]="brand.name">{{ brand.displayName }}</option>
                        </select>
                    </div>
                </div>
                <!--TERMO -->
                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="custom-control custom-radio formVenda-boxTermo px-md-5 pl-5 py-2">
                            <input type="radio" class="custom-control-input" id="defaultUnchecked"
                                name="defaultExampleRadios" mdbInput [disabled]="!leadSaved"/>
                            <label class="custom-control-label" for="defaultUnchecked">Eu li e aceito os
                                <a class="formVenda-link" target="_blank" href="https://hml-vendasonline.wamcomercializacao.com/assets/pdf/termo_empreendimento_{{produto.id}}.pdf">
                                    termos e condições de compra.</a>
                            </label>
                        </div>
                    </div>
                </div>
                <!--BTN FINALIZAR -->
                <div class="row my-3">
                    <div class="col-md-12 text-right">
                        <div *ngIf="validation != ''" class="alert alert-danger">Campo [ {{validation}} ] não informado!</div>
                        <div *ngIf="sendValidation != ''" class="alert alert-danger">{{sendValidation}}</div>
                        <div *ngIf="sendOK" class="alert alert-danger">Dados recebidos!<br>Aguarde, em breve você receberá um contrato para assinatura no e-mail informado.</div>
                        <div *ngIf="!sendOK" class="">

                            <button type="submit" [disabled]="carregando || !leadSaved" class="btn-formVenda-finish text-capitalize px-5 py-3"><span *ngIf="!carregando">Finalizar compra</span><span *ngIf="carregando">Aguarde...</span></button>
                        </div>
                        <div *ngIf="carregando && !sendOK" class="alert alert-danger">
                            <div>
                                <small class="form-text text-muted">{{ saleStep }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>