<!--POPUP LEAD-->
<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right home-btn-close" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="row">
          <div class="col-12">
            <span class="home-popup-title">Realize seu Sonho da Casa de Férias!</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <span class="home-popup-subTitle">Cadastre-se que entraremos em contato.</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-left">
            <label for="formNome">Nome</label>
            <input name="nome"  [(ngModel)]="lead.nome" class="form-control" placeholder="Nome*" type="text" autocomplete="off"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left">
            <label for="formEmail">Email</label>
            <input name="email"  [(ngModel)]="lead.email" class="form-control" placeholder="E-mail*" type="text" autocomplete="off"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left">
            <label for="formTelefone">Telefone</label>
            <input name="telefone" (keyup)="phoneChange($event)" [mask]="phoneMask" [(ngModel)]="lead.telefone" class="form-control" placeholder="Telefone*" type="text" autocomplete="off" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left">
            <label for="formConta">Quanto é 11 + 10?</label>
            <input name="conta"  [(ngModel)]="lead.conta" class="form-control" placeholder="Quanto é 11 + 10?*" type="text" autocomplete="off" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <button mdbBtn type="button" class="btn-home-saibaMais text-capitalize w-100 m-0" mdbWavesEffect (click)="saveLead(); basicModal.hide()">Enviar</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <span class="home-popup-footer">Prometemos não utilizar suas informações de contato para enviar qualquer tipo de SPAM.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BANNER -->
<mdb-carousel class="carousel slide carousel-fade container-first" [animation]="'fade'">
  <mdb-carousel-item *ngFor="let itemCarousel of itemCarousels">
    <div class="view w-100">
      <img
        class="d-block w-100 "
        src="{{ itemCarousel.url }}"
        alt="{{ itemCarousel.name }}"
      />
      <div class="mask" mdbWavesEffect>
        <p class="text-home-title-white">
          {{ itemCarousel.text }}
        </p>
        <button *ngIf="itemCarousel.link === 'home'" mdbBtn type="button" [routerLink]="['./']" fragment="oquee" class="btn-home-saibaMais text-capitalize" mdbWavesEffect>
          Saiba mais
        </button>
        <a *ngIf="itemCarousel.link !== 'home'" class="btn-home-saibaMais text-capitalize" routerLink="{{itemCarousel.link}}">Saiba mais</a>
      </div>
    </div>
  </mdb-carousel-item>
</mdb-carousel>
<!-- /.BANNER -->
<!-- BODY -->
<div class="container mt-5">
  <!-- DESCRICAO -->
  <div class="row home-section" id="oquee">
    <div class="col-md-6 ">
      <div class="row">
        <div class="col-md-12 order-first">
          <p class="text-home-title">
            Aqui você tem a sua Casa de Férias sempre pronta pra usar
          </p>
          <p class="text-home-subTitle">
            Já parou para pensar em todas as vezes que você e sua família desejaram
            viajar mas não puderam se organizar ou pagar pela hospedagem?
          </p>
        </div>
      </div>
      <div class="row d-none d-sm-block">
        <div class="col-md-12">
          <button
            mdbBtn
            type="button"
            [routerLink]="['./']" fragment="Multipropriedade"
            class="btn-home-saibaMais text-capitalize"
            mdbWavesEffect
          >
            Saiba mais
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6 order-2 mt-4 mt-sm-0">
      <div class="row ">
        <div class="col-md-12 ">
          <img src="assets/header_desktop_1.png" class="img-fluid" alt="..." />
        </div>
      </div>
      <div class="row d-block d-sm-none mt-4 mt-sm-0">
        <div class="col-md-12 ">
          <button
            mdbBtn
            type="button"
            [routerLink]="['./']" fragment="Multipropriedade"
            class="btn-home-saibaMais text-capitalize col-md-12"
            mdbWavesEffect
          >
            Saiba mais
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- O QUE É -->
  <div class="row home-section" id="Multipropriedade">
    <div class="col-md-12">
      <p class="text-home-title-2 text-break">
        Multipropriedade. Nós vamos te mostrar todas as vantagens
      </p>
    </div>
  </div>
  <div class="row mt-3 mt-md-5">
    <div class="col-md-6">
      <div class="row mt-3 mt-md-5">
        <div class="col-2 pr-0 text-center">
          <picture>
            <source srcset="assets/partner.svg" type="image/svg+xml" />
            <img src="assets/partner.svg" class="img-fluid icon_diferencial" alt="..." />
          </picture>
        </div>
        <div class="col-10">
          <p class="text-home-subTitle-2">
            Você escolhe um empreendimento em um destino turístico e adquiri uma
            fração do imóvel dividindo o valor total e os custos com outros
            proprietários
          </p>
        </div>
      </div>
      <div class="row mt-3 mt-md-5">
        <div class="col-2 pr-0 text-center">
          ​
          <picture>
            <source srcset="assets/smart-key.svg" type="image/svg+xml" />
            <img src="assets/smart-key.svg" class="img-fluid icon_diferencial" alt="..." />
          </picture>
        </div>
        <div class="col-10">
          <p class="text-home-subTitle-2">
            Os direitos de uso de uma fração são iguais aos de um imóvel normal.
            Tem Escritura e é repassado a herdeiros, podendo também ser
            emprestada e vendida
          </p>
        </div>
      </div>
      <div class="row mt-3 mt-md-5">
        <div class="col-2 pr-0 text-center">
          ​
          <picture>
            <source srcset="assets/aircraft.svg" type="image/svg+xml" />
            <img src="assets/aircraft.svg" class="img-fluid icon_diferencial" alt="..." />
          </picture>
        </div>
        <div class="col-10">
          <p class="text-home-subTitle-2">
            Você pode trocar o seu período para viajar para qualquer lugar no Brasil e no mundo;
          </p>
        </div>
      </div>
      <div class="row mt-3 mt-md-5 d-none d-sm-flex">
        <div class="box-conheca">
          <a class="nav-link" mdbWavesEffect target="_blank" href="https://clubcia.com.br/" >
            Conheça o nosso Clube de Viagens
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row mt-3 mt-md-5">
        <div class="col-2 pr-0 text-center">
          ​
          <picture>
            <source srcset="assets/hotel-1.svg" type="image/svg+xml" />
            <img src="assets/hotel-1.svg" class="img-fluid icon_diferencial" alt="..." />
          </picture>
        </div>
        <div class="col-10">
          <p class="text-home-subTitle-2">
            Você passa a ter um imóvel de férias para sempre e pode utilizar durante algumas semanas por ano. Sabendo desde o início todas as datas disponíveis para você, pagando apenas pelo o que usa 
          </p>
        </div>
      </div>
      <div class="row mt-3 mt-md-5 mb-3 mb-sm-0">
        <div class="col-2 pr-0 text-center">
          <picture>
            <source srcset="assets/hotel.svg" type="image/svg+xml" />
            <img src="assets/hotel.svg" class="img-fluid icon_diferencial" alt="..." />
          </picture>
        </div>
        <div class="col-10">
          <p class="text-home-subTitle-2 mb-0 mb-sm-0">
            Além de tudo isso, a sua Casa de Férias tem Serviço de Quarto,
            Recepção, Manutenção e vai ficar sempre limpa e pronta para receber
            você
          </p>
          <a class="nav-link-conteudo d-block d-sm-none" mdbWavesEffect target="_blank" href="https://clubcia.com.br/" >
            Conheça o nosso Clube de Viagens
          </a>
        </div>
      </div>
      <div class="row mt-5 mt-md-5 d-block d-sm-flex">
        <div class="col-12 home-align-center">
          <a
              class="btn-home-saibaMais text-capitalize"
              href="https://conteudo.wambrasil.com/ebook-como-economizar"
            >
              Baixe o nosso E-book
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- PRODUTOS -->
  <div class="row home-section" id="produtos">
    <div class="col-md-12">
      <p class="text-home-title-2">Escolha a opção que você mais gosta</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p class="text-home-price-blue">
        Parcelas a partir de
        <span class="text-home-price-yellow">R$ 236,90</span>
      </p>
    </div>
  </div>
  <div class="row d-none">
    <div class="col-3 col-md-2">
      <p class="text-home-states-title d-none d-sm-block">Filtre por estado</p>
      <p class="text-home-states-title d-block d-sm-none ">Filtre </p>
    </div>
    <div class="col-9 col-md-10">
      <div class="row home-overflow-filtro">
        <div
          class="text-home-states-box mr-2"
          *ngFor="let itemState of itemStates"
        >
          <a class="nav-link" mdbWavesEffect href="#">
            {{ itemState.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row home-product-box mb-3 p-3" *ngFor="let itemBuild of itemBuilds">
    <div class="col-md-3 home-align-center mt-3 mt-sm-0">
      <img src="{{ itemBuild.Image }}" class="img-fluid rounded" alt="..." />
    </div>
    <div class="col-md-3">
      <div class="row mt-3 mt-sm-0">
        <div class="col-md-12">
          <p class="home-product-title">{{ itemBuild.name }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="home-product-subTitle">{{ itemBuild.Endereco }}</p>
        </div>
      </div>
      <div class="row d-none d-sm-block">
        <div class="col-md-12">
          <picture class="home-product-icon ml-3">
            <source srcset="{{ itemBuild.icone }}" type="image/svg+xml" />
            <img src="{{ itemBuild.icone }}" class="img-fluid" alt="..." />
          </picture>
        </div>
      </div>
      <div class="row d-none d-sm-block">
        <div class="col-md-12">
          <p class="home-product-subTitle-2">{{ itemBuild.Diferencial }}</p>
        </div>
      </div>
      <div class="row d-sm-none home-diferencial">
        <div class="col-3">
          <picture class="home-product-icon">
            <source srcset="{{ itemBuild.icone }}" type="image/svg+xml" />
            <img src="{{ itemBuild.icone }}" class="img-fluid" alt="..." />
          </picture>
        </div>
        <div class="col-9">
          <p class="home-product-subTitle-2">{{ itemBuild.Diferencial }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row mt-3 mt-sm-0">
        <div class="col-2 pr-0 text-center">
          <picture class="home-product-check">
            <source srcset="assets/check.svg" type="image/svg+xml" />
            <img src="assets/check.svg" class="img-fluid" alt="..." />
          </picture>
        </div>
        <div class="col-10 pl-0">
          <p class="home-product-subTitle">{{ itemBuild.detalhe_1 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2 pr-0 text-center">
          <picture>
            <source srcset="assets/check.svg" type="image/svg+xml" />
            <img src="assets/check.svg" class="img-fluid" alt="..." />
          </picture>
        </div>
        <div class="col-10 pl-0">
          <p class="home-product-subTitle">{{ itemBuild.detalhe_2 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2 pr-0 text-center">
          <picture>
            <source srcset="assets/check.svg" type="image/svg+xml" />
            <img src="assets/check.svg" class="img-fluid" alt="..." />
          </picture>
        </div>
        <div class="col-10 pl-0">
          <p class="home-product-subTitle">{{ itemBuild.detalhe_3 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2 pr-0 text-center">
          <picture>
            <source srcset="assets/check.svg" type="image/svg+xml" />
            <img src="assets/check.svg" class="img-fluid" alt="..." />
          </picture>
        </div>
        <div class="col-10 pl-0">
          <p class="home-product-subTitle">{{ itemBuild.detalhe_4 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2 pr-0 text-center">
          <picture>
            <source srcset="assets/check.svg" type="image/svg+xml" />
            <img src="assets/check.svg" class="img-fluid" alt="..." />
          </picture>
        </div>
        <div class="col-10 pl-0">
          <p class="home-product-subTitle">{{ itemBuild.detalhe_5 }}</p>
        </div>
      </div>
    </div>
    <!--DESKTOP-->
    <div class="col-md-3 d-none d-sm-block">
      <div class="row justify-content-left col-md-12">
        <div class="box-date">
          <span class="nav-link">
            {{ itemBuild.Status }}
          </span>
        </div>
      </div>
      <div class="row col-md-12 justify-content-left mt-3">
        <p class="home-product-subTitle pb-1 mb-1">Parcelas a partir de</p>
        <p class="home-product-price pt-0 mt-0">R${{ itemBuild.Price }}</p>
      </div>
      <div class="row justify-content-left">
        <button mdbBtn type="button" class="btn-home-saibaMais text-capitalize" routerLink="{{ itemBuild.url }}" mdbWavesEffect>Tenho interesse</button>
      </div>
    </div>
    <!--MOBILE-->
    <div class="col-md-3 d-sm-none">
      <div class="row">
        <div class="col-5 pr-0">
          <div class="box-date">
            <span class="nav-link-entrega">
              {{ itemBuild.Status }}
            </span>
          </div>
        </div>
        <div class="col-7">
          <p class="home-product-subTitle pb-1 mb-1">Parcelas a partir de</p>
          <p class="home-product-price pt-0 mt-0">R${{ itemBuild.Price }}</p>
        </div>
      </div>
      <div class="row ">
        <div class="col-12">
          <button mdbBtn type="button" class="btn-home-saibaMais text-capitalize col-12 mx-0 my-2" routerLink="{{ itemBuild.url }}" mdbWavesEffect>Tenho interesse</button>
        </div>
      </div>
    </div>
  </div>
  <!-- DESTINOS -->
  <div class="row home-section d-none">
    <!--MOBILE-->
    <div class="col-md-6 d-sm-none">
      <img src="assets/header_desktop.png" class="img-fluid" alt="..." />
    </div>
    <div class="col-md-6">
      <div class="row px-3">
        <p class="text-home-title">Destinos no Brasil e no mundo</p>
        <p class="text-home-subTitle">
          Saiba mais sobre as cidades e o que você pode fazer em cada lugar. Nós
          temos guias para você aproveitar ainda mais.
        </p>
      </div>
      <div class="row px-3">
        <div
          class="text-home-states-box mr-2 mt-2"
          *ngFor="let itemState of itemStates"
        >
          <a class="nav-link" mdbWavesEffect href="#">
            {{ itemState.place }}
          </a>
        </div>
      </div>
    </div>
    <!--DESKTOP-->
    <div class="col-md-6 d-none d-sm-block">
      <img src="assets/header_desktop.png" class="img-fluid" alt="..." />
    </div>
  </div>
  <!-- CLIENTES -->
  <div class="row home-section" id="clubecia">
    <div class="col-md-6">
      <p class="text-home-title">Club Cia</p>
      <p class="home-subTitle-blue">
        Conheça o seu clube de fidelidade exclusivo
      </p>
      <p class="text-home-subTitle">Garanta a sua Casa de Férias e ganhe milhares de vantagens</p>
      
    </div>
    <div class="col-md-6 text-center">
      <div class="box">
        <!---->
        <iframe
          class="home-video"
          src="https://www.youtube.com/embed/z26qMkV2HaY?controls=0"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <!---->
        <div class="text">
          <img
            src="assets/clubecia_logo.png"
            class="img-fluid"
            alt="Responsive image"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- CLUBE CIA -->
  <div class="row home-section text-center px-5" id="depoimentos">
    <div class="col-md-12 px-md-5 px-0">
      <p class="home-subTitle-blue text-center">
        A WAM realiza sonhos, sonhos de quem sempre quis se entregar ao mundo de
        corpo e alma, conhecendo novos lugares e costumes.
      </p>
      <p class="text-home-subTitle text-center">
        Veja o que nossos clientes falam sobre essas experiências
      </p>
    </div>
  </div>
  <div class="row mt-5 text-center">
    <div class="col-md-12">
      <!---->
      <iframe
        class="home-video"
        src="https://www.youtube.com/embed/D8LqOEdw0iA"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <!---->
    </div>
  </div>
  <div class="row justify-content-center mt-md-5 my-5">
    <button mdbBtn type="button" class="btn-home-saibaMais text-capitalize d-none d-sm-block" [routerLink]="['home']" fragment="produtos" mdbWavesEffect>Conheça as nossas opções de produtos</button>
    <button mdbBtn type="button" class="btn-home-saibaMais text-capitalize d-sm-none col-12" [routerLink]="['home']" fragment="produtos" mdbWavesEffect>Veja as Opções</button>
  </div>
</div>

