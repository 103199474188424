import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestsService {

  apiURL = (environment.production) ? 'https://apis.wamcomercializacao.com/prod/' : 'https://apis.wamcomercializacao.com/dev/';

  constructor(
    private http: HttpClient
  ) { }

  public loadAPIAppData(operation: string, body: any): Observable<any> {
    try {
      // monta a url de acordo com a operação
      const urlApi = this.apiURL + operation;
      // montando cabeçalho
      const awsApiKey = 'n9FijD9YNj5tpvbSIvBqc1oWq0woRXu9XvkSkFX3';
      let httpHeaders: HttpHeaders;
      httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': awsApiKey
      });
      const options = {
        headers: httpHeaders
      };
      // consultando API
      return this.http.post(urlApi, body, options);
    } catch (e) {
      console.error(e);
    }
  }
}
