<div class="container mt-5 mb-5 container-first">
    <div class="row">
        <div class="col-md-6 text-right">
            <img src="assets/parabens.png" class="img-fluid" alt="..." />
        </div>
        <div class="col-md-6">
            <p class="parabens-title">
                Parabéns
            </p>
            <p class="parabens-subTitle">
                Estamos quase lá!
                <br>
                <br>
                Em breve você receberá o contrato em seu e-mail, com o assunto:
                <br>
                <br>
                Bem-vindo ao seu mundo compartilhado WAM.
                <br>
                <br>
                Após a sua assinatura e de sua testemunha, em até 48 horas o contrato será enviado assinado por todas as partes.
                <br>
                <br>
                Conhece alguém que também amaria realizar o sonho da casa de férias?
            </p>
        </div>
    </div>
    <div class="row mt-5 mb-5">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <p class="parabens-title-black">Compartilhe a WAM</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="col-md-12 text-center">
                        <picture class="parabens-icon mr-3">
                            <source srcset="assets/Face.svg" type="image/svg+xml">
                            <img src="assets/Face.svg">
                        </picture>
                        <picture class="parabens-icon ml-3">
                            <source srcset="assets/whatsapp(1).svg" type="image/svg+xml">
                            <img src="assets/whatsapp(1).svg">
                        </picture>
                        <picture class=" ml-1 mr-0">
                            <source srcset="assets/mail.svg" type="image/svg+xml">
                            <img src="assets/mail.svg">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5 mb-5">
        <div class="col-md-12 text-center">
            <a class="parabens-link" href="/home">Continuar navegando</a>
        </div>
    </div>
</div>