import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DuvidasComponent } from './duvidas/duvidas.component';
import { HomeComponent } from './home/home.component';
import { FormVendaComponent } from './form-venda/form-venda.component';
import { ProdutoDetailComponent } from './produto-detail/produto-detail.component';
import { ParabensComponent } from './parabens/parabens.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'duvidas', component: DuvidasComponent },
  { path: 'home', component: HomeComponent },
  { path: 'home/home', component: HomeComponent },
  { path: 'comprar/:id', component: FormVendaComponent },
  { path: 'produto/:id', component: ProdutoDetailComponent },
  { path: 'parabens', component: ParabensComponent },
  { path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }