import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { HttpRequestsService } from '../services/http-requests.service';
import produtos from 'src/assets/produtos.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'mdb-angular-free';
  private fragment: string;

  lead = {
    nome: '',
    telefone: '',
    email: '',
    conta:''
  };
  phoneMask = '(00) 0000-00009';

  @ViewChild('basicModal') basicModal: ModalDirective;

  itemMenus = [
    {"id":1,"name":"O que é Multipropriedade?","url":"#"},
    {"id":2,"name":"Produtos","url":"#"},
    {"id":3,"name":"Clube Cia","url":"#"},
    {"id":4,"name":"Depoimentos","url":"#"},
    {"id":5,"name":"Dúvidas","url":"#"}
  ];;
  itemCarousels = [
    {"id":0,"name":"Olimpia","url":"assets/4.jpg","text":"O Olímpia Park Resort chegou!","link":"/produto/6451"},
    {"id":1,"name":"image1","url":"assets/0.jpg","text":"Seu sonho da casa de férias agora é realidade!","link":"home"},
    {"id":2,"name":"image2","url":"assets/1.jpg","text":"Seu sonho da casa de férias agora é realidade!","link":"home"},
    {"id":3,"name":"image3","url":"assets/2.jpg","text":"Seu sonho da casa de férias agora é realidade!","link":"home"},
    {"id":4,"name":"image4","url":"assets/3.jpg","text":"Seu sonho da casa de férias agora é realidade!","link":"home"}
  ];;  
  itemStates = [
    {"id":1,"name":"São Paulo","place":"São Paulo - SP","url":"#"},
    {"id":2,"name":"Rio de Janeiro","place":"Búzios - RJ","url":"#"},
    {"id":3,"name":"Bahia","place":"Porto Seguro - BA","url":"#"},
    {"id":4,"name":"Goiás","place":"Caldas Novas - GO","url":"#"},
    {"id":5,"name":"Todos","place":"Campos do Jordão - SP","url":"#"}
  ];;

  public itemBuilds = produtos;

  constructor(
    private route: ActivatedRoute,
    private http: HttpRequestsService
    ) { }

  public phoneChange(event) {
    const phoneNumber = String(event.target.value).replace(/\D/g, '');
    if (phoneNumber.length <= 0) {
      this.phoneMask = '00';
    } else if (phoneNumber.length < 11) {
      this.phoneMask = '(00) 0000-00009';
    } else {
      this.phoneMask = '(00) 0 0000-0000';
    }
  }

  public saveLead(): void {
    if ((this.lead.nome !== '' || this.lead.email !== '' || this.lead.telefone !== '') && parseInt(this.lead.conta) === 21) {
      this.http.loadAPIAppData('saveLead', this.lead).subscribe((res: any) => {
        if (!res.success) {
          console.error(res);
        }
      });
    }
  }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    
    setTimeout(() => {
      this.basicModal.show();
    }, 1000);
  }  
  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }
}
