import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViacepService {

  constructor(
    private http: HttpClient
  ) { }

  public getAddressByCEP(cep: string): Observable<ViaCEP> {
    const stripCEP = cep.replace(/\D/g, '');
    const viaCEPurl = `https://viacep.com.br/ws/${stripCEP}/json/`;
    return this.http.get(viaCEPurl).pipe(map((res: any) => {
      if (res.erro) {
        // tslint:disable-next-line: no-use-before-declare
        return new ViaCEP();
      } else {
        // tslint:disable-next-line: no-use-before-declare
        return new ViaCEP(res);
      }
    },
    err => {
      // tslint:disable-next-line: no-use-before-declare
      return new ViaCEP();
    }));
  }
}

export class ViaCEP {

  status: boolean;
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: number;
  gia: string;

  constructor(viacep?: any) {
    this.status = (viacep && viacep.cep) ? true : false;
    this.cep = (viacep && viacep.cep) ? viacep.cep : null;
    this.logradouro = (viacep && viacep.logradouro) ? viacep.logradouro : null;
    this.complemento = (viacep && viacep.complemento) ? viacep.complemento : null;
    this.bairro = (viacep && viacep.bairro) ? viacep.bairro : null;
    this.localidade = (viacep && viacep.localidade) ? viacep.localidade : null;
    this.uf = (viacep && viacep.uf) ? viacep.uf : null;
    this.unidade = (viacep && viacep.unidade) ? viacep.unidade : null;
    this.ibge = (viacep && viacep.ibge) ? Number(viacep.ibge) : null;
    this.gia = (viacep && viacep.gia) ? viacep.gia : null;
  }
}
