import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {
  title = 'mdb-angular-free';
  navDefault = ['/home','/home#oquee','/home#produtos','/home#clubecia','/home#depoimentos','/home#Multipropriedade','/home/home#produtos'];
  navClean = ['/parabens','/comprar/541','/comprar/6450','/comprar/553','/comprar/542','/comprar/543','/comprar/6451','/duvidas'];
  navProductDetail = ['/produto/541',
  '/produto/541#vantagens',
  '/produto/541#localizacao',
  '/produto/541#ficha',
  '/produto/541#clubecia',
  '/produto/6450',
  '/produto/6450#vantagens',
  '/produto/6450#localizacao',
  '/produto/6450#ficha',
  '/produto/6450#clubecia',
  '/produto/6451',
  '/produto/6451#vantagens',
  '/produto/6451#localizacao',
  '/produto/6451#ficha',
  '/produto/6451#clubecia',
  '/produto/553',
  '/produto/553#vantagens',
  '/produto/553#localizacao',
  '/produto/553#ficha',
  '/produto/553#clubecia',
  '/produto/542',
  '/produto/542#vantagens',
  '/produto/542#localizacao',
  '/produto/542#ficha',
  '/produto/542#clubecia',
  '/produto/543',
  '/produto/543#vantagens',
  '/produto/543#localizacao',
  '/produto/543#ficha',
  '/produto/543#clubecia'];
  footerClean = ['/parabens','/comprar/541','/comprar/6450','/comprar/6451','/comprar/553','/comprar/542','/comprar/543'];
  itemMenus = [
    {"id":1,"name":"O que é Multipropriedade?","url":"#"},
    {"id":2,"name":"Produtos","url":"#"},
    {"id":3,"name":"Clube Cia","url":"#"},
    {"id":4,"name":"Depoimentos","url":"#"},
    {"id":5,"name":"Dúvidas","url":"#"}
  ];;
  itemCarousels = [
    {"id":1,"name":"image1","url":"assets/0.jpg"},
    {"id":2,"name":"image2","url":"assets/1.jpg"},
    {"id":3,"name":"image3","url":"assets/2.jpg"},
    {"id":4,"name":"image4","url":"assets/3.jpg"}
  ];;
  itemStates = [
    {"id":1,"name":"São Paulo","place":"São Paulo - SP","url":"#"},
    {"id":2,"name":"Rio de Janeiro","place":"Búzios - RJ","url":"#"},
    {"id":3,"name":"Bahia","place":"Porto Seguro - BA","url":"#"},
    {"id":4,"name":"Goiás","place":"Caldas Novas - GO","url":"#"},
    {"id":5,"name":"Todos","place":"Campos do Jordão - SP","url":"#"}
  ];;
  itemBuilds = [
    {"id":1,"name":"Varandas Thermas Park","Image":"assets/Varandas.jpg","Endereco":"Caldas Novas - GO","Diferencial":"Com parque aquático","Status":"Entrega em Junho/2022","Price":"236,90"}
  ];;
    constructor(public router: Router) { 
    }
    ngOnInit() {
    }
}