import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  private fragment: string;
  title = 'mdb-angular-free';
  itemMenus = [
    {"id":1,"name":"O que é Multipropriedade?","url":"Multipropriedade"},
    {"id":2,"name":"Produtos","url":"produtos"},
    {"id":3,"name":"Clube Cia","url":"clubecia"},
    {"id":4,"name":"Depoimentos","url":"depoimentos"}
  ];;
  itemCarousels = [
    {"id":1,"name":"image1","url":"assets/0.jpg"},
    {"id":2,"name":"image2","url":"assets/1.jpg"},
    {"id":3,"name":"image3","url":"assets/2.jpg"},
    {"id":4,"name":"image4","url":"assets/3.jpg"}
  ];;
  itemStates = [
    {"id":1,"name":"São Paulo","place":"São Paulo - SP","url":"#"},
    {"id":2,"name":"Rio de Janeiro","place":"Búzios - RJ","url":"#"},
    {"id":3,"name":"Bahia","place":"Porto Seguro - BA","url":"#"},
    {"id":4,"name":"Goiás","place":"Caldas Novas - GO","url":"#"},
    {"id":5,"name":"Todos","place":"Campos do Jordão - SP","url":"#"}
  ];;
  itemBuilds = [
    {"id":1,"name":"Varandas Thermas Park","Image":"assets/Varandas.jpg","Endereco":"Caldas Novas - GO","Diferencial":"Com parque aquático","Status":"Entrega em Junho/2022","Price":"236,90"}
  ];;
  constructor(private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }  
  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }
}
