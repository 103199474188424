import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-clean',
  templateUrl: './footer-clean.component.html',
  styleUrls: ['./footer-clean.component.scss']
})
export class FooterCleanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
