<!-- Footer -->
<footer class="page-footer home-page-footer font-small pt-4">
    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left footer-page-container">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-4 mt-3 text-left">
          <!-- Content DESKTOP-->
          <div class="row ml-md-3  d-none d-sm-block">
            <div class="col-md-12 ">
              <picture >
                <source srcset="assets/WAM-comercializacao.svg" type="image/svg+xml">
                <img src="assets/WAM-comercializacao.svg">
              </picture>
            </div>
          </div>
          <div class="row mt-3 ml-md-3  d-none d-sm-block">
            <div class="footer-box ">
              <a class="footer-link mx-3" mdbWavesEffect [routerLink]="" fragment="produtos">
                Conheça nossos produtos
              </a>
            </div>
          </div>
          <!-- Content MOBILE-->
          <div class="row ml-md-3 d-sm-none">
            <div class="col-4 home-align-center">
              <picture >
                <source srcset="assets/WAM-comercializacao.svg" type="image/svg+xml">
                <img src="assets/WAM-comercializacao.svg">
              </picture>
            </div>
            <div class="col-8 home-align-center">
              <div class="footer-box ">
                <a class="footer-link" mdbWavesEffect [routerLink]="" fragment="produtos">
                  Conheça nossos produtos
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid column -->
        <div class="col-md-4 mb-md-0 mb-3 d-none d-sm-block">
          <!-- Links -->
          <ul class="list-unstyled">
            <li *ngFor="let itemMenu of itemMenus">
              <a class="footer-link" mdbWavesEffect [routerLink]="" fragment="{{itemMenu.url}}">{{itemMenu.name}}</a>
            </li>
            <li  class="nav-item">
              <a class="footer-link" mdbWavesEffect routerLink="/duvidas">Dúvidas</a>
            </li>
          </ul>
        </div>
        <!-- Grid column -->
        <div class="col-md-4 mb-md-0 mb-3">
          <div class="row">
            <div class="col-md-12">
              <p class="footer-title">Nossos Contatos</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <picture >
                <source srcset="assets/email-84.svg" type="image/svg+xml">
                <img src="assets/email-84.svg">
              </picture>
              <span class="footer-subTitle ml-2">atendimento@wambrasil.com</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <picture >
                <source srcset="assets/Telephone.svg" type="image/svg+xml">
                <img src="assets/Telephone.svg">
              </picture>
              <span class="footer-subTitle ml-2">3003-0720</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="footer-box px-3 ">
                <a class="footer-link" mdbWavesEffect href="https://api.whatsapp.com/send?phone=5562998611633&text=Vendas%20Online" target="_blank">
                  <img src="assets/whatsapp.png" height="27px" alt="" /> Fale conosco pelo Whatsapp
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="footer-title">Nossas Redes Sociais</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a href="https://www.instagram.com/wambrasil/" target="_blank">
                <picture class="mr-3">
                  <source srcset="assets/Insta.svg" type="image/svg+xml">
                  <img src="assets/Insta.svg">
                </picture>
              </a>
              <a href="https://www.facebook.com/WAMBrasil/" target="_blank">
                <picture class="mr-3">
                  <source srcset="assets/Face.svg" type="image/svg+xml">
                  <img src="assets/Face.svg">
                </picture>
              </a>
              <a href="https://www.youtube.com/channel/UCpPJrccaQp0cDDKoTf8wULQ" target="_blank">
                <picture>
                  <source srcset="assets/Tube.svg" type="image/svg+xml">
                  <img src="assets/Tube.svg">
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Grid row -->
    </div>
    <!-- Footer Links -->
    <hr class="footer-line ">
    <!-- Copyright -->
    <div class="text-center pb-3">
      Endereço: Av. Niemeyer, 769 - Sala 201. São Conrado, Rio de Janeiro, Brasil <br>Copyright© 2020. WAM Comercialização. Todos os direitos reservados. 
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->